<div *ngIf="(!isLoading && !hasError) else loadingOrError">

  <header class="d-flex justify-content-between p-4">
    <button type="button" mat-flat-button color="accent" (click)="addPartner()" >
      <mat-icon class="mat-18 icon-display">add</mat-icon>
      <span >Adicionar Sócio</span>
    </button>
    <button type="button" mat-flat-button color="accent"  (click)="toggleEditMode()" *ngIf="partnerList.length">
      <mat-icon class="mat-18">mode_edit</mat-icon>
      <span>Editar</span>
    </button>
  </header>
  <main class="p-4 pt-0 d-flex ">
    <section class="w-25">
      <mat-selection-list>
        <mat-list-option
          *ngFor="let partner of partnerList"
          class="d-flex justify-content-between align-items-center p-2 ps-2 mb-1"
          [ngClass]="{success: true}"
          (click)="onChangePartner(partner)"
          [value]="partner"
        >
          <div class="d-flex flex-column">
            <span class="primary-text ">{{ partner.name  || '-' }}</span>
            <span class="secundary-text ">{{ partner.cpfCnpj   || '-'}}</span>
          </div>
          <mat-icon class="mat-18" *ngIf="partner.subscriber">edit_note</mat-icon>
        </mat-list-option>
      </mat-selection-list>
    </section>

    <section class="w-75 p-4 pt-0" *ngIf="partnerList.length" >
      <form [formGroup]="formPartner">
        <div class="row">
          <mat-form-field appearance="outline" class="col">
            <mat-label>CPF/CNPJ</mat-label>
            <input matInput required formControlName="cpfCnpj"
              [readonly]="!editMode"
              [ngClass]="{'not-allow-edit': !editMode}"
              mask="000.000.000-00||00.000.000/0000-00"
            >
          </mat-form-field>

          <mat-form-field appearance="outline" class="col">
            <mat-label>Nome Completo</mat-label>
            <input matInput required formControlName="name"
              [readonly]="!editMode"
              [ngClass]="{'not-allow-edit': !editMode}"
            >
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field appearance="outline" class="col">
            <mat-label>Telefone</mat-label>
            <input matInput required formControlName="phone"
              [readonly]="!editMode"
              [ngClass]="{'not-allow-edit': !editMode}"
              mask="(00) 000000009"
            >
          </mat-form-field>

          <mat-form-field appearance="outline" class="col" [ngClass]="{'not-allow-edit': !editMode}">
            <mat-label>Qualificação</mat-label>

            <mat-select
              required formControlName="qualificationCode"
              placeholder="Selecione um item"
            >
              <mat-option
                *ngFor="let qualification of qualificationList"
                [value]="qualification.code"
                [disabled]="!editMode"
              >
                {{qualification.code}} - {{ qualification.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field appearance="outline" class="col">
            <mat-label>E-mail</mat-label>
            <input matInput required formControlName="mail"
              [readonly]="!editMode"
              [ngClass]="{'not-allow-edit': !editMode}"
            >
          </mat-form-field>
        </div>

        <div class="mb-4">
          <mat-slide-toggle color="accent" formControlName="subscriber" class="mb-3">
            Assinante: Pessoa responsável pela empresa conforme contrato social. Deve possuir e-cpf
          </mat-slide-toggle>
          <mat-slide-toggle color="accent" formControlName="solidaryPartner">
            Responsável solidário: deve possuir e-cpf
          </mat-slide-toggle>
          <div *ngIf="subscribePartner !== undefined && !partnerSelected.subscriber" class="alert mt-2" role="alert" >
            Para alterar este sócio como assinante, é necessário desabilitar a assinatura do sócio <b>{{subscribePartner?.name}}</b>
          </div>
        </div>

        <div>
          <div *ngIf="!subscribePartner" class="alert alert-warning mt-2 d-flex align-items-center mr-2" role="alert">
            <mat-icon>info</mat-icon>
            <span class="ms-2">Para finalizar o cadastro dos sócios é necessário ter no <b>mínimo um sócio assinante.</b></span>
          </div>

          <div *ngIf="!hasSolidary" class="alert alert-warning mt-2 d-flex align-items-center mr-2" role="alert">
            <mat-icon>info</mat-icon>
            <span class="ms-2">Para finalizar o cadastro dos sócios é necessário ter no <b>mínimo um sócio solidário.</b></span>
          </div>
        </div>

        <div class="d-flex flex-column mb-4">
          <span class="primary-text">Documentos</span>
          <span class="secundary-text"><b>Os documentos são salvos automaticamente conforme a importação.</b></span>
        </div>

        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel *ngFor="let doc of documentList">
            <mat-expansion-panel-header>
              <mat-panel-title class="d-flex justify-content-between">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <span class="me-2">
                      {{doc.description}}
                    </span>
                    <mat-icon
                      *ngIf="doc.isRequired"
                      [ngClass]="!!doc.files.length ? 'text-success' : 'text-danger' "
                    >
                      {{ !!doc.files.length ? "check_circle" : "error" }}
                    </mat-icon>
                  </div>
                  <span *ngIf="doc?.files?.length" class="text-success">{{doc?.files?.length }} documento(s) importado(s)</span>
                  <span *ngIf="!doc?.files?.length && doc?.isRequired" class="text-danger mt-n4">Documento pendente</span>
                </div>
                <div>
                  <button mat-stroked-button color="accent"
                    (click)="fileInput.click()" type="button"
                    *ngIf="!doc.isLoading"
                    [disabled]="!editMode"
                  >
                    <span>Importar</span>
                    <input class="d-none" type="file"
                      #fileInput
                      accept="'.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF'"
                      multiple
                      (change)="addDocuments($event, doc.type)"
                    >
                  </button>
                  <button mat-flat-button class="ml-3 mr-1" color="accent" *ngIf="doc.isLoading" disabled>
                    IMPORTANDO
                    <mat-spinner class="d-inline-block ml-3" [diameter]="20"></mat-spinner>
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>


            <p
              class="d-flex align-items-center"
              *ngFor="let file of doc.files"
            >
              <button mat-icon-button color="warn" (click)="deleteDocument(file)" [disabled]="!editMode">
                <mat-icon class="mat-18"  *ngIf="!file?.isDeleting">delete</mat-icon>
                <mat-spinner *ngIf="file?.isDeleting" [diameter]="20"  mat-icon-button class="mx-2">
                </mat-spinner>
              </button>
              <span>{{file.name}}</span>
            </p>

          </mat-expansion-panel>
        </mat-accordion>


      </form>
      <footer class=" mt-4 d-flex justify-content-between">
        <button type="button" mat-stroked-button color="warn" (click)="deletePartner()">
          <mat-icon class="mat-18">delete</mat-icon>
          <span>Deletar Sócio</span>
        </button>

        <button type="button" mat-flat-button color="accent" (click)="save()">
          <mat-icon class="mat-18">save</mat-icon>
          <span>Salvar</span>
        </button>
      </footer>
    </section>


  </main>
</div>

<ng-template #loadingOrError>
  <ng-container *ngIf="isLoading; else error">
    <div class="spinner-container-std">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </ng-container>

  <ng-template #error>
    <div class="d-flex justify-content-center align-items-center">
      <img src="assets/svg/error.svg" alt="Imagem representando a ocorrência de erro"
      class="img-std-error">
    </div>
  </ng-template>
</ng-template>
