import { HttpClient, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Bank, PartnerInterface } from '../interfaces/register-drawee.interface';
import { environment } from 'src/environments/environment';
import { Observable, first, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterDraweeService {

  private idSacado!: string;
  private readonly url = environment.register_api + `secure-api`;
  @Output() saving = new EventEmitter<boolean>();

  constructor(private httpClient: HttpClient) {}


  getCompany(type?: string): Observable<any>{
    return this.httpClient
      .get<any>(this.url + `/company/${this.idSacado}/${type || ''}`)
      .pipe(take(1));
  }

  editCompany(data: any, type?: string){
    this.saving.emit(true);
    return this.httpClient.put<any>(`${this.url}/company/${this.idSacado}/${type || ''}`, data)
  }

  getBanks(): Observable<Bank[]>{ /* use getCompany() and pass banks as type */
    return this.httpClient
      .get<Bank[]>(this.url + `/company/${this.idSacado}/banks`)
      .pipe(take(1));
  }

  addBank(bank: any){
    return this.httpClient.post(this.url + `/company/${this.idSacado}/banks/`, bank);
  }

  deleteBank(id: string,){
    return this.httpClient.delete(this.url + `/company/${this.idSacado}/banks/${id}/`);
  }

  getPhones(){
    return this.httpClient
      .get(this.url + `/company/${this.idSacado}/contact`)
      .pipe(take(1));
  }

  addPhone(contact: any){
    return this.httpClient.post(this.url + `/company/${this.idSacado}/contact/`, contact);
  }

  deletePhone(id: string){
    return this.httpClient.delete(this.url + `/company/${this.idSacado}/contact/${id}/`);
  }

  editPartner(partner: PartnerInterface,  ){
    this.saving.emit(true);
    return this.httpClient.put<PartnerInterface>(`${this.url}/company/${this.idSacado}/partners/${partner.id}/`, partner);
  }

  addDocument(files: Set<File>, type: string, idPartner?: string){
    const formData = new FormData();
    files.forEach(file => formData.append('files', file, file.name));
    formData.append('type', type);

    const url = idPartner
              ? `${this.url}/company/${this.idSacado}/partner/${idPartner}/document/all/`
              : `${this.url}/company/${this.idSacado}/document/all/`;

    return this.httpClient.post<any>(url, formData, {headers: {media: "true"}});
  }

  deleteDocument(idDoc:string, idPartner?: string){
    return this.httpClient.delete(
      idPartner
      ? `${this.url}/company/${this.idSacado}/partner/${idPartner}/document/${idDoc}`
      : `${this.url}/company/${this.idSacado}/document/${idDoc}/`
    )
  }

  setId(id: string){
    this.idSacado = id;
  }

  deletePartner(idPartner: any){
    return this.httpClient.delete(`${this.url}/company/${this.idSacado}/partners/${idPartner}/`).pipe(first())
  }
  addPartner(data: any){
    return this.httpClient.post(`${this.url}/company/${this.idSacado}/partners/`, data).pipe(first())
  }
}
