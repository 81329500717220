import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DraweeService } from '../../services/drawee.service';

@Component({
  selector: 'app-exit-register-dialog',
  templateUrl: './exit-register-dialog.component.html',
  styleUrls: ['./exit-register-dialog.component.scss'],
})
export class ExitRegisterDialogComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<any>,
    public draweeService: DraweeService
  ) {}

  exitRegister() {
    this.draweeService.invoice.next(null);
    this.draweeService.invoiceSaved.next(false);
    this.draweeService.canAddInvoice.next(false);
    this.draweeService.registeredDrawee.next(null);
    this.draweeService.registeredInvoices.next([]);
    this.draweeService.retrySubmit.next(false);
    this.dialogRef.close();
    this.router.navigate(['']);
  }
}
