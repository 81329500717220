import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { OperationsComponent } from './pages/operations/operations.component';

const routes: Routes = [
  {
    path: '',
    component: OperationsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: ':access_key',
    component: OperationsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'error/not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: 'cadastro/dados-nota-fiscal',
    loadChildren: () =>
      import('./modules/invoice/invoice.module').then((m) => m.InvoiceModule),
    canActivate: [AuthGuard],
  },

  {
    path: '**',
    component: NotAuthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
