<mat-accordion class="headers-align" multi>
  <mat-expansion-panel
    [expanded]="draweeExpanded"
    (opened)="handleOpenExpansionPanel('drawee')"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="dropdown-title"
        >DADOS DO SACADO
        <mat-icon
          matTooltip="O que significa sacado? Sacado é o seu cliente, ou seja, o destinatário do produto ou tomador do serviço da sua nota fiscal."
          >help</mat-icon
        >
        {{ draweeName }}
      </mat-panel-title>

      <mat-icon class="success-icon" *ngIf="draweeFormSaved || formSaved"
        >check_circle</mat-icon
      >
    </mat-expansion-panel-header>

    <app-drawee-form
      (draweeNameChanges)="draweeNameChanges($event)"
      (registered)="onRegisteredDrawee($event)"
    ></app-drawee-form>
  </mat-expansion-panel>

  <mat-expansion-panel
    [expanded]="invoiceExpanded"
    (opened)="handleOpenExpansionPanel('invoice')"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>DADOS DA NOTA</mat-panel-title>

      <mat-icon class="success-icon" *ngIf="formSaved">check_circle</mat-icon>
    </mat-expansion-panel-header>

    <app-invoice-data-form
      (registered)="onRegisteredInvoice($event)"
    ></app-invoice-data-form>
  </mat-expansion-panel>
</mat-accordion>
