<mat-form-field appearance="outline" class="filter-cnpj-social">
  <mat-label>
    <mat-icon>search</mat-icon>
    Pesquisar por CNPJ/Razão Social
  </mat-label>
  <input
    class="input-autocomplete"
    placeholder="Digite o CNPJ ou a Razão Social"
    matInput
    [formControl]="filter"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    [displayWith]="displayOptions.bind(this)"
    #auto="matAutocomplete"
  >
    <mat-option *ngIf="!options.length && !isLoading" disabled
      >{{ notFoundLabel ?? "Empresa não encontrada" }}
    </mat-option>
    <mat-option *ngIf="isLoading" disabled>Carregando...</mat-option>

    <mat-option
      *ngFor="let option of options"
      [value]="option.cnpj"
      (click)="onSelected(option)"
      >{{ option.name }} - {{ formatedCnpj(option.cnpj) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
