import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import {
  DefaultDocumentInterface,
  DocumentInterface,
} from './document.interface';

@Component({
  selector: 'app-document-dropdown',
  templateUrl: './document-dropdown.component.html',
  styleUrls: ['./document-dropdown.component.scss'],
})
export class DocumentDropdownComponent {
  _defaultDocuments: DefaultDocumentInterface[] = []; // o que vem da api
  _documents: DocumentInterface[] = []; // o que é adicionado manualmente ou CTe
  _isLoading = false;
  _disabled = false;

  @Input() set defaultDocuments(defaultDocuments: DefaultDocumentInterface[]) {
    this._defaultDocuments = defaultDocuments;
  }

  @Input() set documents(documents: DocumentInterface[]) {
    this._documents = documents;
  }

  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  @Output() onChange = new EventEmitter<
    { file: File; deletable?: boolean }[]
  >();

  @Output() onDelete = new EventEmitter<{ id: string; index: number }>();

  constructor() {}

  deleteDefaultDocument(id: string, index: number) {
    this.onDelete.emit({ id, index });
  }

  deleteDocument(index: number) {
    this._documents.splice(index, 1);
  }

  onChangeDoc(event: any) {
    const files = event.srcElement.files as FileList;
    this.onChange.emit(
      Array.from(files).map((file) => ({ file, deletable: true }))
    );
  }
}
