import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierService } from 'src/app/services/notifier.service';
import { DraweeService } from '../../services/drawee.service';
import { errorMessages } from 'src/app/constants/error-messages.constant';

@Component({
  selector: 'app-add-invoice-modal',
  templateUrl: './add-invoice-modal.component.html',
  styleUrls: ['./add-invoice-modal.component.scss'],
})
export class AddInvoiceModalComponent {
  isLoading = false;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<any>,
    public draweeService: DraweeService,
    public snackbar: MatSnackBar,
    private notifierService: NotifierService
  ) {}

  navigateTo(page: string) {
    this.dialogRef.close(true);
    this.router.navigate([page]);
  }

  addDocumentInvoice(event: any) {
    this.isLoading = true;
    const document = event.srcElement.files[0];
    this.draweeService.getDraweeByInvoice(document).subscribe({
      next: (res) => {
        this.isLoading = false;

        if (res.type === 'CTE') {
          this.draweeService.invoiceDocument.next(document);
        }

        this.draweeService.invoice.next(res);
        this.navigateTo('cadastro/dados-nota-fiscal');
      },
      error: (response) => {
        this.isLoading = false;
        let message = 'Erro ao importar nota';
        if (
          response?.error?.message === 'NOTE_ALREADY_REGISTERED' ||
          response?.error?.message === 'LEAD_NOT_ACTIVE'
        ) {
          message =
            errorMessages[
              response.error.message as keyof typeof errorMessages
            ] || message;
        }

        this.notifierService.showNotification({
          message,
          messageType: 'errorNotifier',
          alternative: 'contate o time de operação.',
        });
      },
    });
  }
}
