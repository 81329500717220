<mat-toolbar>
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
    (click)="onBack()"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span>Cadastro de nota fiscal</span>
</mat-toolbar>

<div class="main">
  <mat-stepper #stepper [linear]="true">
    <mat-step [completed]="receiptDataEnabled">
      <div>
        <ng-template matStepLabel>Dados da nota fiscal</ng-template>

        <div class="title-container">
          <span>Confira os dados antes de salvar</span>
        </div>

        <div class="invoice-form-container">
          <div *ngFor="let invoice of invoices; let i = index">
            <div class="invoice-title-container">
              <p class="dropdown-label" *ngIf="invoices.length > 1">
                Nota {{ i + 1 }}
              </p>

              <button
                mat-button
                class="delete-installment"
                *ngIf="i !== 0 && i === invoices.length - 1 && !lastFormSaved"
                (click)="onDeleteInvoice(i)"
                color="warn"
              >
                Remover nota
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <app-invoice-form></app-invoice-form>
          </div>
        </div>

        <div class="button-container">
          <button
            mat-flat-button
            class="button-save"
            matStepperNext
            [disabled]="!receiptDataEnabled"
            [class.button-disabled]="!receiptDataEnabled"
          >
            Ir para dados do recebimento
          </button>
          <button
            mat-flat-button
            class="button-cancel"
            [disabled]="!canAddInvoice"
            [class.button-disabled]="!receiptDataEnabled"
            (click)="onAddInvoice()"
          >
            Adicionar mais uma NF
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step completed="false">
      <div>
        <ng-template matStepLabel>Dados do recebimento</ng-template>

        <app-receipt-data (back)="goBack(stepper)"></app-receipt-data>
      </div>
    </mat-step>
  </mat-stepper>
</div>
