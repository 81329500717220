<div mat-dialog-content>
  <div class="title-alert-container">
    <mat-icon>warning</mat-icon>
    <p class="alert-title">Você tem certeza que deseja Remover essa parcela?</p>
  </div>
  <div class="options">
    <button
      mat-flat-button
      mat-dialog-close
      class="button-sair"
      (click)="deleteInstallment()"
    >
      Sim, remover parcela
    </button>
    <button mat-flat-button class="button-finish" mat-dialog-close>
      Continuar Cadastro
    </button>
  </div>
</div>
