import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { error } from 'console';
import { debounceTime, of, switchMap } from 'rxjs';
import { FilteredCompanyInterface } from 'src/app/interfaces/filtered-company.interface';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { maskCnpj } from 'src/app/utils/string-to-cnpj-mask';

@Component({
  selector: 'app-autocomplete-filter',
  templateUrl: './autocomplete-filter.component.html',
  styleUrls: ['./autocomplete-filter.component.scss'],
})
export class AutocompleteFilterComponent {
  filter = new FormControl();
  isLoading = false;
  options: FilteredCompanyInterface[] = [];

  @Input() companyType?: string;
  @Input() notFoundLabel?: string;
  @Output() selected = new EventEmitter<FilteredCompanyInterface>();

  constructor(private service: customerDashboardService) {
    this.listenDraweeFilter();
  }

  listenDraweeFilter() {
    this.filter.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((value: string): any => {
          if (
            value?.trimStart() !== '' &&
            value?.trimEnd() !== '' &&
            value?.length > 2
          ) {
            this.getCompany(value);
          }

          return of('');
        })
      )
      .subscribe();
  }

  getCompany(filter: string) {
    if (/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(filter)) {
      filter = filter.replace(/\D/g, '');
    }
    this.isLoading = true;
    this.service.getCompanies(this.companyType, filter).subscribe({
      next: (res) => {
        this.options = res.content;
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }

  displayOptions(cnpj?: string) {
    if (!cnpj) return '';

    const { name } = this.options.find((_: any) => _.cnpj === cnpj)!;

    return `${name} - ${maskCnpj(cnpj)}`;
  }

  formatedCnpj(cnpj: string) {
    return maskCnpj(cnpj);
  }

  onSelected(selectedOption: FilteredCompanyInterface) {
    this.selected.emit(selectedOption);
  }
}
