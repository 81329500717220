  <div class="container" *ngIf="(!isLoading && !hasError); else loadingOrError">
  <form
    [formGroup]="formCompany"
    class="p-4 pb-0"
  >
    <div class="d-flex justify-content-between" >
      <h2 class="color-std-title">Dados</h2>
      <button type="button" mat-flat-button color="accent" (click)="toggleEditMode()">
        <mat-icon class="mat-18">mode_edit</mat-icon>
        <span>Editar</span>
      </button>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Razão Social</mat-label>
        <input matInput required formControlName="name"
          [ngClass]="{'not-allow-edit': !editMode}"
          [readonly]="!editMode"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-6" >
        <mat-label>Nome Fantasia</mat-label>
        <input matInput required formControlName="tradeName"
          [ngClass]="{'not-allow-edit': !editMode}"
          [readonly]="!editMode"
        >
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>CNPJ</mat-label>
        <input matInput required formControlName="cnpj"
          [ngClass]="{'not-allow-edit': !editMode}"
          [readonly]="!editMode"
          mask="00.000.000/0000-00"
        >
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="col-md-3"
        [ngClass]="{'not-allow-edit': !editMode}"
      >
        <mat-label>Ramo de Atividade</mat-label>
        <mat-select
          placeholder="Selecione uma das opções"
          required formControlName="activity"
        >
            <mat-option
              [value]="activity"
              *ngFor="let activity of activityOptions"
              [disabled]="!editMode"
            >
              {{ activitySectorRef[activity] }}
            </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>Capital Social</mat-label>
        <input matInput required formControlName="sharedCapital"
          [ngClass]="{'not-allow-edit': !editMode}"
          [readonly]="!editMode"
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
          align="left"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>Faturamento Anual</mat-label>
        <input matInput required formControlName="annualBilling"
          [ngClass]="{'not-allow-edit': !editMode}"
          [readonly]="!editMode"
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left'  }"
        >
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>Telefone da Empresa</mat-label>
        <input matInput required formControlName="phone"
          [ngClass]="{'not-allow-edit': !editMode}"
          [readonly]="!editMode"
          mask="(00) 000000009"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>Data de Abertura</mat-label>
        <input matInput required formControlName="openedOn"
          [ngClass]="{'not-allow-edit': !editMode}"
          [readonly]="!editMode"
          mask="00/00/0000"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3" [ngClass]="{'not-allow-edit': !editMode}">
        <mat-label>Unidade</mat-label>
        <mat-select
          required formControlName="unit"
          placeholder="Selecione um item"
        >
          <mat-option
            *ngFor="let unit of unitOptions"
            [value]="unit"
            [disabled]="!editMode"
          >
            {{unitTypesRef[unit]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3" [ngClass]="{'not-allow-edit': !editMode}">
        <mat-label>Regime Tributário</mat-label>
        <mat-select
          placeholder="Selecione um item" required
          formControlName="regime"
        >
            <mat-option
              [value]="regime"
              *ngFor="let regime of regimeOptions"
              [disabled]="!editMode"
            >
              {{ taxRegimesRef[regime] }}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <form  [formGroup]="formBank" class="p-4 pt-0" #formDirective="ngForm" (ngSubmit)="addBank(formDirective)">
    <h2 class="color-std-title">Dados Bancários</h2>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md" [ngClass]="{'not-allow-edit': !editMode}">
        <mat-label>Banco</mat-label>
        <mat-select placeholder="Selecione um Banco" required formControlName="name">
          <mat-option [value]="bank" *ngFor="let bank of bankOptions"  [disabled]="!editMode">
            {{ bank.COMPE }} - {{ bank.ShortName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-2">
        <mat-label>Agência</mat-label>
        <input matInput required formControlName="agency"
        [readonly]="!editMode"
        [ngClass]="{'not-allow-edit': !editMode}"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-2">
        <mat-label>Conta Corrente</mat-label>
        <input matInput required formControlName="account"
        [readonly]="!editMode"
        [ngClass]="{'not-allow-edit': !editMode}"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-1" >
        <mat-label>Digito</mat-label>
        <input matInput required formControlName="accountDigit"
          [readonly]="!editMode"
          [ngClass]="{'not-allow-edit': !editMode}"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-2">
        <mat-label>Pix (opcional)</mat-label>
        <input matInput formControlName="pix"
          [readonly]="!editMode"
          [ngClass]="{'not-allow-edit': !editMode}"
        >
      </mat-form-field>
      <button
        color="accent"
        mat-stroked-button
        class="col-md-2 p-1"
        style="height: 56px;"
        type="submit"
        [disabled]="!editMode"
        [ngClass]="{'not-allow-edit': !editMode}"
      >
        <mat-icon class="mat-18">add</mat-icon>
        <span><b>Adicionar</b></span>
      </button>
    </div>
    <div class="row" *ngFor="let bank of bankList">
      <mat-form-field appearance="outline" class="col-md">
        <mat-label>Banco</mat-label>
        <input matInput
          disabled
          class="not-allow-edit"
          [value]="bank.name"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-2">
        <mat-label>Agência</mat-label>
        <input matInput
          disabled
          class="not-allow-edit"
          [value]="bank.agency"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-2">
        <mat-label>Conta Corrente</mat-label>
        <input matInput
          disabled
          class="not-allow-edit"
          [value]="bank.account"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-1" >
        <mat-label>Digito</mat-label>
        <input matInput
          disabled
          class="not-allow-edit"
          [value]="bank.accountDigit"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-2">
        <mat-label>Pix (opcional)</mat-label>
        <input matInput disabled class="not-allow-edit" [value]="bank.pix">
      </mat-form-field>
      <button
        color="warn" mat-stroked-button
        class="col-md-2 p-1"
        style="height: 56px;"
        (click)="deleteBank(bank.id)"
        [disabled]="!editMode"
        [ngClass]="{'not-allow-edit': !editMode}"
      >
        <mat-icon class="mat-18">delete</mat-icon>
        <span><b>Remover</b></span>
      </button>
    </div>
  </form>
</div>

<ng-template #loadingOrError>
  <ng-container *ngIf="isLoading; else error">
    <div class="spinner-container-std">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </ng-container>

  <ng-template #error>
    <div class="d-flex justify-content-center align-items-center">
      <img src="assets/svg/error.svg" alt="Imagem representando a ocorrência de erro"
      class="img-std-error">
    </div>
  </ng-template>
</ng-template>



