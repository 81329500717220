import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegisterDraweeService } from 'src/app/services/register-drawee.service';
import { QualificationList } from 'src/app/utils/qualification-list';
import { RegisterDrawee } from 'src/app/utils/register-drawee';

@Component({
  selector: 'app-partner-modal',
  templateUrl: './partner-modal.component.html',
  styleUrls: ['./partner-modal.component.scss']
})
export class PartnerModalComponent {
  formsPartner!: FormGroup;
  qualificationList = QualificationList.list();
  bool = false;
  @Output() add = new EventEmitter(false);

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PartnerModalComponent>
  ){
    this.formsPartner = this.formBuilder.group({
      cpfCnpj: [null, Validators.required],
      name: [null, Validators.required],
      phone: [null, Validators.required],
      mail: [null, Validators.required],
      qualificationCode: [null, Validators.required],
    })
  };

  save(){
    if(!this.formsPartner.valid) return;
    this.add.emit(this.formsPartner.value);
    this.dialogRef.close();
  }

}
