import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  Document,
  PartnerInterface,
} from 'src/app/interfaces/register-drawee.interface';
import { RegisterDraweeService } from 'src/app/services/register-drawee.service';
import { QualificationList } from 'src/app/utils/qualification-list';
import { RegisterDrawee } from 'src/app/utils/register-drawee';
import { PartnerModalComponent } from '../../partner-modal/partner-modal.component';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-register-drawee-partner',
  templateUrl: './register-drawee-partner.component.html',
  styleUrls: ['./register-drawee-partner.component.scss'],
})
export class RegisterDraweePartnerComponent implements OnInit {
  formPartner!: FormGroup;
  editMode = false;
  qualificationList = QualificationList.list();

  partnerList: PartnerInterface[] = [];
  partnerSelected!: PartnerInterface;
  subscribePartner!: PartnerInterface | undefined;
  documentList = RegisterDrawee.partnerDocumentList();

  @Output() validation = new EventEmitter<boolean>();
  @Output() saving = new EventEmitter<boolean>();
  hasSolidary = false;
  hasSubscribe = false;
  isLoading = true;
  hasError = false;

  constructor(
    private formBuilder: FormBuilder,
    private draweeService: RegisterDraweeService,
    public dialog: MatDialog,
    private notifierService: NotifierService
  ) {
    this.formPartner = this.formBuilder.group({
      id: [null],
      cpfCnpj: [null, Validators.required],
      name: [null, Validators.required],
      phone: [null, Validators.required],
      qualificationCode: [null, Validators.required],
      mail: [null, Validators.required],
      subscriber: [null],
      solidaryPartner: [null],
    });
  }

  ngOnInit() {
    this.getPartnerList();
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  getPartnerList() {
    this.isLoading = true;
    this.draweeService.getCompany('partners').subscribe({
      next: (partnerList: PartnerInterface[]) => {
        if (!partnerList.length) return;

        this.partnerList = partnerList;

        this.formPartner.patchValue({
          id: partnerList[0].id,
          cpfCnpj: partnerList[0].cpfCnpj,
          name: partnerList[0].name,
          phone: partnerList[0].phone,
          qualificationCode: partnerList[0].qualificationCode,
          mail: partnerList[0].mail,
          subscriber: partnerList[0].subscriber,
          solidaryPartner: partnerList[0].solidaryPartner,
        });

        this.partnerSelected = partnerList[0];
        this.setSubscribePartner();

        this.pushFiles(this.partnerSelected);

        this.hasSolidary = this.hasSolidaryPartner();

        this.isValid();
        this.isLoading = false;
      },

      error: () => {
        this.isLoading = false;
        this.hasError = true;
        this.notifierService.getDataError();
      },
    });
  }

  onChangePartner(partnerSelected: PartnerInterface) {
    this.setPartnerSelected(partnerSelected);
    this.pushFiles(partnerSelected);

    this.formPartner.patchValue({
      id: this.partnerSelected.id,
      name: this.partnerSelected.name,
      mail: this.partnerSelected.mail,
      phone: this.partnerSelected.phone,
      cpfCnpj: this.partnerSelected.cpfCnpj,
      qualificationCode: this.partnerSelected.qualificationCode,
      subscriber: this.partnerSelected.subscriber,
      solidaryPartner: this.partnerSelected.solidaryPartner,
    });
  }

  save() {
    this.emitSaving(true);
    this.editPartnerSelected(this.formPartner.value);
    this.draweeService.editPartner(this.partnerSelected).subscribe({
      next: () => {
        this.emitSaving(false);
        this.setSubscribePartner();
        this.hasSolidary = this.hasSolidaryPartner();
        this.isValid();
      },
      error: () => {
        this.emitSaving(false);
        this.notifierService.saveError();
      },
    });
  }

  editPartnerSelected(formsPartner: any) {
    let index = this.partnerList.findIndex(
      (partner) => partner.id === this.partnerSelected.id
    );

    this.partnerList[index].cpfCnpj = formsPartner.cpfCnpj;
    this.partnerList[index].name = formsPartner.name;
    (this.partnerList[index].phone = formsPartner.phone),
      (this.partnerList[index].qualificationCode =
        formsPartner.qualificationCode);
    this.partnerList[index].mail = formsPartner.mail;
    this.partnerList[index].solidaryPartner = formsPartner.solidaryPartner;

    this.setPartnerSelected(this.partnerList[index]);

    /* Caso nao exista um socio assinante... */
    if (
      !(
        !!formsPartner.subscriber &&
        this.subscribePartner &&
        this.subscribePartner.id !== this.partnerSelected.id
      )
    ) {
      this.partnerList[index].subscriber = formsPartner.subscriber;
    }
  }

  addPartner() {
    const dialogRef = this.dialog.open(PartnerModalComponent, {
      width: '800px',
    });

    dialogRef.componentInstance.add.subscribe((newPartner) => {
      this.draweeService.addPartner(newPartner).subscribe({
        next: (data) => {
          this.getPartnerList();
          this.notifierService.addPartnerSuccess();
        },

        error: () => {
          this.notifierService.addPartnerError();
        },
      });
    });
  }

  deletePartner() {
    this.draweeService.deletePartner(this.partnerSelected.id).subscribe({
      next: () => {
        const index = this.partnerList.findIndex(
          (partner) => this.partnerSelected.id == partner.id
        );
        this.partnerList.splice(index, 1);
        this.isValid();
        this.notifierService.dellPartnerSuccess();
      },

      error: () => {
        this.notifierService.dellPartnerError();
      },
    });
  }

  setPartnerSelected(partnerSelected: PartnerInterface) {
    this.partnerSelected = partnerSelected;
  }

  addDocuments(event: any, type: string) {
    const foundDoc = this.documentList.find((doc) => doc.type == type);
    foundDoc!.isLoading = true;

    const fileSelected = <FileList>event.srcElement.files;
    let setFile: Set<File> = new Set();

    for (let i = 0; i < fileSelected.length; i++) {
      setFile.add(fileSelected[i]);
    }

    this.draweeService
      .addDocument(setFile, type, this.partnerSelected.id)
      .subscribe({
        next: (data) => {
          foundDoc!.isLoading = false;
          foundDoc?.files.push(...data);
          this.isValid();
          this.notifierService.addDocSuccess();
        },
        error: () => {
          this.notifierService.addPartnerError();
        },
      });
  }

  deleteDocument(document: Document) {
    const indexType = document.type == 'IDENTIFICACAO' ? 0 : 1;
    const index = this.documentList[indexType].files.findIndex(
      (file) => document.id == file.id
    );
    this.documentList[indexType].files[index].isDeleting = true;

    const idPartner = document.companyPartnerId || document.partner;
    this.draweeService.deleteDocument(document.id, idPartner).subscribe({
      next: () => {
        this.documentList[indexType].files[index].isDeleting = false;
        this.documentList[indexType].files.splice(index, 1);
        this.isValid();
        this.notifierService.dellDocSuccess();
      },
      error: () => {
        this.notifierService.dellDocError();
      },
    });
  }

  hasSolidaryPartner() {
    return !!this.partnerList.find(
      (partner) => partner.solidaryPartner == true
    );
  }

  setSubscribePartner() {
    this.subscribePartner = this.partnerList.find(
      (partner) => partner.subscriber == true
    );
  }

  formsIsValid(): boolean {
    for (let i = 0; i < this.partnerList.length; i++) {
      if (
        !(
          !!this.partnerList[i].cpfCnpj &&
          !!this.partnerList[i].name &&
          !!this.partnerList[i].phone &&
          !!this.partnerList[i].qualificationDescrition &&
          !!this.partnerList[i].mail
        )
      ) {
        return false;
      }
    }
    return true;
  }

  isValid() {
    this.validation.emit(
      this.formsIsValid() &&
        this.hasSolidary &&
        !!this.subscribePartner &&
        !!this.documentList[0].files.length &&
        !!this.documentList[1].files.length
    );
  }

  pushFiles(partner: PartnerInterface) {
    this.documentList[0].files = [];
    this.documentList[1].files = [];

    if (!partner.documents) return;

    partner.documents.forEach((doc) => {
      doc.type == 'IDENTIFICACAO'
        ? this.documentList[0].files.push(doc)
        : this.documentList[1].files.push(doc);
    });
  }

  emitSaving(bool: boolean) {
    this.saving.emit(bool);
  }
}
