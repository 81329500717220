import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { NotifierInterface } from 'src/app/interfaces/notifier.interface';

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss'],
})
export class NotifierComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: Omit<NotifierInterface, 'messageType'>,
    public snackBarRef: MatSnackBarRef<NotifierComponent>
  ) {}
}
