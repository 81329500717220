import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExitRegisterDialogComponent } from '../exit-register-dialog/exit-register-dialog.component';
import { MatStepper } from '@angular/material/stepper';
import { AddInvoiceModalComponent } from '../add-invoice-modal/add-invoice-modal.component';
import { DraweeService } from '../../services/drawee.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice-form-screen',
  templateUrl: './invoice-form-screen.component.html',
  styleUrls: ['./invoice-form-screen.component.scss'],
})
export class InvoiceFormScreenComponent implements AfterViewInit, OnDestroy {
  canAddInvoice = false;
  invoices = [null];
  registeredInvoices: string[] = [];
  lastFormSaved = false;

  constructor(private draweeService: DraweeService, private dialog: MatDialog) {
    this.listenCanAddInvoiceChanges();
    this.listenRegisteredInvoiceChanges();
    this.listenInvoiceSaved();
  }

  ngOnDestroy() {
    this.draweeService.invoice.next(null);
    this.draweeService.invoiceSaved.next(false);
    this.draweeService.canAddInvoice.next(false);
    this.draweeService.registeredDrawee.next(null);
    this.draweeService.registeredInvoices.next([]);
    this.draweeService.retrySubmit.next(false);
    this.draweeService.invoiceDocument.next(null);
  }

  get receiptDataEnabled() {
    return this.registeredInvoices.length === this.invoices.length;
  }

  listenCanAddInvoiceChanges() {
    this.draweeService.canAddInvoice.subscribe({
      next: (canAddInvoice) => {
        this.canAddInvoice = canAddInvoice;
      },
    });
  }

  listenRegisteredInvoiceChanges() {
    this.draweeService.registeredInvoices.subscribe({
      next: (registeredInvoices) =>
        (this.registeredInvoices = registeredInvoices),
    });
  }

  listenInvoiceSaved() {
    this.draweeService.invoiceSaved.subscribe({
      next: (invoiceSaved) => (this.lastFormSaved = invoiceSaved),
    });
  }

  @ViewChild(MatStepper) stepper!: MatStepper;
  ngAfterViewInit() {
    this.stepper._getIndicatorType = () => 'number';
  }

  onBack() {
    this.dialog.open(ExitRegisterDialogComponent, { autoFocus: false });
  }

  onAddInvoice() {
    this.draweeService.invoice.next(null);
    this.draweeService.invoiceDocument.next(null);

    const dialogRef = this.dialog.open(AddInvoiceModalComponent, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoices.push(null);
        this.draweeService.registeredDrawee.next(null);
        this.draweeService.canAddInvoice.next(false);
        this.draweeService.invoiceSaved.next(false);
      }
    });
  }

  onDeleteInvoice(index: number) {
    this.invoices.splice(index, 1);
    this.draweeService.registeredDrawee.next(null);
    this.draweeService.invoice.next(null);
    this.draweeService.canAddInvoice.next(true);
    this.draweeService.invoiceSaved.next(true);
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }
}
