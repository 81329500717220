<main class="p-4" *ngIf="(!isLoading && !hasError)else loadingOrError">
  <div class="d-flex justify-content-between">
    <div class="example-action-buttons mb-2 ">
      <button mat-stroked-button (click)="accordion.openAll()" class="me-2">Expandir Todos</button>
      <button mat-stroked-button (click)="accordion.closeAll()">Recolher Todos</button>
    </div>

    <button type="button" mat-flat-button color="accent" (click)="toggleEditMode()">
      <mat-icon class="mat-18">mode_edit</mat-icon>
      <span>Editar</span>
    </button>
  </div>

  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel *ngFor="let accordionDoc of accordionDocuments">
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex justify-content-between">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <span class="me-2">
                {{accordionDoc.description}}
              </span>
              <mat-icon
                *ngIf="accordionDoc.isRequired"
                [ngClass]="!!accordionDoc.files.length ? 'text-success' : 'text-danger' "
              >
                {{ !!accordionDoc.files.length ? "check_circle" : "error" }}
              </mat-icon>
            </div>
            <span *ngIf="accordionDoc?.files?.length" class="text-success">{{accordionDoc?.files?.length }} documento(s) importado(s)</span>
            <span *ngIf="!accordionDoc?.files?.length && accordionDoc?.isRequired" class="text-danger mt-n4">Documento pendente</span>
          </div>
          <div>
            <button mat-stroked-button color="accent"
              (click)="fileInput.click()" type="button"
              *ngIf="!accordionDoc?.isLoading"
              [disabled]="!editMode"
            >
              <span>Importar</span>
              <input class="d-none" type="file"
                #fileInput
                multiple
                (change)="addDocuments($event, accordionDoc.type)"

              >
            </button>
            <button mat-flat-button class="ml-3 mr-1" *ngIf="accordionDoc?.isLoading" color="accent" disabled>
              IMPORTANDO
              <mat-spinner class="d-inline-block ml-3" [diameter]="20"></mat-spinner>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <p
        class="d-flex align-items-center"
        *ngFor="let docFile of accordionDoc.files"
      >
        <button mat-icon-button color="warn" (click)="deleteDocument(docFile)" [disabled]="!editMode">
          <mat-icon class="mat-18">delete</mat-icon>
          <mat-spinner *ngIf="docFile?.isDeleting" [diameter]="20"  mat-icon-button class="mx-2"></mat-spinner>
        </button>
        <span>{{docFile.name}}</span>
      </p>

    </mat-expansion-panel>
  </mat-accordion>
</main>


<ng-template #loadingOrError>
  <ng-container *ngIf="isLoading; else error">
    <div class="spinner-container-std">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </ng-container>

  <ng-template #error>
    <div class="d-flex justify-content-center align-items-center">
      <img src="assets/svg/error.svg" alt="Imagem representando a ocorrência de erro"
      class="img-std-error">
    </div>
  </ng-template>
</ng-template>
