<h1 mat-dialog-title class="title">Antecipação solicitada com sucesso!</h1>
<mat-dialog-content class="mat-typography" class="container">

    <figure class="container-img">
        <img src="assets/png/assinatura 1.png" alt="Imagem representendo uma assinatura">
    </figure>

    <h2 class="container-title">Entenda o que acontece agora.</h2>

    <div class="container-step">
        <span class="container-step-title">Etapa 1</span>
        <p class="container-step-description">O seu título foi para venda e logo que um comprador confirmar a operação o processo será continuado. </p>
    </div>

    <div class="container-step">
        <span class="container-step-title">Etapa 2</span>
        <p class="container-step-description">Assim que um comprador terminar de analisar sua nota, ele enviará um e-mail para o seu cliente informando-o da antecipação. É importante que você avise o seu cliente deste processo. Informe-o de que em alguns casos precisará responder o e-mail para que a operação seja continuada.</p>
    </div>

    <div class="container-step">
        <span class="container-step-title">Etapa 3</span>
        <p class="container-step-description">Assim que o seu cliente responder o e-mail enviado pelo comprador do título, você receberá um outro e-mail com um link para assinar a operação na plataforma <strong>BryCloud</strong>. Você também pode clicar em “Assinar” no status Aguardando assinatura para ser redirecionado a Bry.</p>
    </div>
    <div class="container-btn">
        <button mat-button mat-dialog-close>Ok</button>
    </div>
    
   
</mat-dialog-content>


