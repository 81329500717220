import { Component } from '@angular/core';

@Component({
  selector: 'app-insufficient-credit-modal',
  templateUrl: './insufficient-credit-modal.component.html',
  styleUrls: ['./insufficient-credit-modal.component.scss']
})
export class InsufficientCreditModalComponent {

}
