import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatTableDataSource,
  MatTableDataSourcePaginator,
} from '@angular/material/table';
import { finalize, map, take } from 'rxjs';
import { TitleListInterface } from 'src/app/interfaces/analysis-operations.interface';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { OperationTypeList } from 'src/app/utils/operation-type-list';
import { RegisterDraweeModalComponent } from '../register-drawee-modal/register-drawee-modal.component';
import { MatDialog } from '@angular/material/dialog';

interface OperationListInterface extends TitleListInterface {
  dataSource: MatTableDataSource<OperationLead, MatTableDataSourcePaginator>;
}

@Component({
  selector: 'app-analysis-titles-view',
  templateUrl: './analysis-titles-view.component.html',
  styleUrls: ['./analysis-titles-view.component.scss'],
})
export class AnalysisTitlesViewComponent implements OnInit {
  dataSource = new MatTableDataSource<OperationLead>();
  loading!: boolean;
  pageIndex: number = 0;
  pageSize: number = 20;
  leadsService: any;
  isLoading = false;
  totalFaceValue: number = 0;

  totalData!: number;

  leads: OperationLead[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  customerStatus = 'IN_ANALYSIS';
  filters?: FiltersInterface = { status: this.customerStatus };

  displayedColumns: string[] = [
    'download',
    'registerDrawee',
    'sacado',
    'Broker',
    'OperationType',
    'comprador',
    'Parcela',
    'dueDateEnd',
    'type',
    'numberInvoice',
    'totalAmount',
    'valueLiquid',
  ];
  operationList!: OperationListInterface[];

  constructor(
    private customerDashboardService: customerDashboardService,
    private _snackBar: MatSnackBar,
    private notifierService: NotifierService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getCustomers();
  }

  ngAfterViewInit() {}
  getTotalFaceValue() {
    this.customerDashboardService.getTotalFaceValue(this.filters).subscribe({
      next: (totalFaceValue) => {
        this.totalFaceValue = totalFaceValue;
      },
    });
  }
  filterData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  lastPage = false;

  getCustomers(data?: FiltersInterface, pageIndex?: number, pageSize?: number) {
    if (data) {
      this.filters = {
        status: this.customerStatus,
        ...data,
      };
    }

    if (!this.lastPage) {
      this.isLoading = true;

      this.customerDashboardService
        .getAll(this.pageIndex, this.pageSize, this.filters)
        .pipe(
          map(({ content, ...rest }) => {
            const invoicesWithWrongInstallments =
              this.checkInvoicesWithWrongInstallments(content);

            if (invoicesWithWrongInstallments?.length) {
              invoicesWithWrongInstallments.forEach((invoiceNumber) => {
                content = content.map((invoice) => {
                  return this.correctInvoiceInstallments(
                    invoice,
                    invoiceNumber
                  );
                });
              });
            }
            return { content, ...rest };
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (data) => {
            if (this.pageIndex === 0) this.leads = data.content;
            else this.leads = this.leads.concat(data.content);
            if (!data.last) this.pageIndex = this.pageIndex + 1;
            this.lastPage = data.last;
            this.getTotalFaceValue();
          },
          error: (error) => {
            this.notifierService.showNotification({
              message: 'Problema ao carregar dados!',
              alternative: 'Tente novamente mais tarde.',
              messageType: 'errorNotifier',
            });
          },
        });
    }
  }
  handleSubmit(data?: FiltersInterface) {
    this.pageIndex = 0;
    this.lastPage = false;
    this.getCustomers(data);
  }
  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }
  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.customerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      }),
      (err: any) => {
        this._snackBar.open('Erro ao baixar documento', 'OK', {
          duration: 5000,
        });
      };
  }

  openDialogRegisterDrawee(row: any) {
    this.dialog.open(RegisterDraweeModalComponent, {
      data: { sacadoId: row.sacadoId },
    });
  }

  checkInvoicesWithWrongInstallments(
    invoice: OperationLead[]
  ): OperationLead['numberInvoice'][] {
    return invoice
      .filter((invoice) => {
        const installmentAsString = String(invoice.numberInstallment);
        const hasWrongInstallment =
          installmentAsString.startsWith('0') &&
          installmentAsString.charAt(1) === '/';
        return hasWrongInstallment;
      })
      .map((invoice) => invoice.numberInvoice);
  }

  correctInvoiceInstallments(
    invoice: OperationLead,
    invoiceNumber: OperationLead['numberInvoice']
  ) {
    if (invoice.numberInvoice === invoiceNumber) {
      const numberInstallmentAsString = String(invoice.numberInstallment);
      const [firstHalf, secondHalf] = numberInstallmentAsString.split('/');
      const correctNumber = +firstHalf + 1;
      const correctInstallmentNumber = String(correctNumber).concat(
        '/',
        secondHalf
      );
      return { ...invoice, numberInstallment: correctInstallmentNumber };
    }
    return { ...invoice };
  }
}
