import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-modal-installment',
  templateUrl: './delete-modal-installment.component.html',
  styleUrls: ['./delete-modal-installment.component.scss'],
})
export class DeleteModalInstallmentComponent {
  constructor(private router: Router, public dialogRef: MatDialogRef<any>) {}

  deleteInstallment() {
    this.dialogRef.close({ delete: true });
  }
}
