<div mat-dialog-content>
  <div class="title-alert-container">
    <mat-icon>warning</mat-icon>
    <p class="alert-title">Você tem certeza que deseja Sair?</p>
    <p>
      Caso não tenha finalizado o seu cadastro, seus dados não serão salvos..
    </p>
  </div>
  <div class="options">
    <button mat-flat-button class="button-sair" (click)="exitRegister()">
      Sair
    </button>
    <button mat-flat-button mat-dialog-close class="button-finish">
      Continuar cadastro
    </button>
  </div>
</div>
