import { OperationTypeList } from 'src/app/utils/operation-type-list';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatTableDataSource,
  MatTableDataSourcePaginator,
} from '@angular/material/table';
import { take } from 'rxjs';
import { TitleListInterface } from 'src/app/interfaces/analysis-operations.interface';
import {
  DocumentInstallmentInterface,
  DocumentInterface,
} from 'src/app/interfaces/documents-status.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { AditivoModalComponent } from '../aditivo-modal/aditivo-modal.component';

import { NotifierService } from 'src/app/services/notifier.service';
import { RegisterDraweeModalComponent } from '../register-drawee-modal/register-drawee-modal.component';

interface OperationListInterface extends DocumentInterface {
  dataSource: MatTableDataSource<DocumentInstallmentInterface>;
}

@Component({
  selector: 'app-documents-titles',
  templateUrl: './documents-titles.component.html',
  styleUrls: ['./documents-titles.component.scss'],
})
export class DocumentsTitlesComponent implements OnInit {
  operationList: OperationListInterface[] = [];
  dataSource = new MatTableDataSource<OperationLead>();
  displayedColumns: string[] = [
    'download',
    'registerDrawee',
    'sacados',
    'Broker',
    'OperationType',
    'type',
    'dueDateEnd',
    'totalAmount',
    'valueLiquid',
  ];
  isLoading = false;
  leads!: OperationLead[];
  totalFaceValue: number = 0;

  pageIndex: number = 0;
  pageSize: number = 20;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  selection = new SelectionModel<OperationLead>(true, []);

  constructor(
    private customerDashboardService: customerDashboardService,
    public dialog: MatDialog,
    private notifierService: NotifierService,

    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getDocumentOperation();
  }

  ngAfterViewInit() {}

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }
  checkboxLabel(row: OperationLead): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      this.dataSource.data.indexOf(row) + 1
    }`;
  }

  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.customerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      }),
      (err: any) => {
        this._snackBar.open('Erro ao baixar documento', 'OK', {
          duration: 5000,
        });
      };
  }
  openDialogAditive(additiveNumber: string, nameBuyer: string) {
    const dialogRef = this.dialog.open(AditivoModalComponent, {
      data: { additiveNumber, nameBuyer },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  getDocumentOperation(data?: any) {
    this.isLoading = true;
    this.customerDashboardService
      .getDocumentOperation()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.operationList = res.content.map((operation) => ({
            ...operation,

            amountPaid: operation.installments
              .map((i) => i.amountPaid)
              .reduce((p, total) => (total += p)),
            totalAmount: operation.installments
              .map((i) => i.totalAmount)
              .reduce((p, total) => (total += p)),

            dataSource: new MatTableDataSource<DocumentInstallmentInterface>(
              operation.installments
            ),
          }));
          this.isLoading = false;
          this.getTotalFaceValue();
        },
        error: (error) => {
          this.isLoading = false;
          this.notifierService.showNotification({
            message: 'Problema ao carregar dados!',
            alternative: 'Tente novamente mais tarde.',
            messageType: 'errorNotifier',
          });
        },
      });
  }
  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }
  getTotalFaceValue() {
    this.customerDashboardService
      .getTotalFaceValue({ status: 'DOCUMENT' })
      .subscribe({
        next: (totalFaceValue) => {
          this.totalFaceValue = totalFaceValue;
        },
      });
  }

  openDialogRegisterDrawee(row: any) {
    this.dialog.open(RegisterDraweeModalComponent, {
      data: { sacadoId: row.sacadoId },
    });
  }
}
