import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { take, map, finalize } from 'rxjs';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { SimulateReceivablesModalComponent } from '../simulate-receivables-modal/simulate-receivables-modal.component';

import { OperationTypeList } from 'src/app/utils/operation-type-list';
import { RegisterDraweeModalComponent } from '../register-drawee-modal/register-drawee-modal.component';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-disponibles-titles',
  templateUrl: './disponibles-titles.component.html',
  styleUrls: ['./disponibles-titles.component.scss'],
})
export class DisponiblesTitlesComponent implements OnInit {
  isLoading = false;
  leads: OperationLead[] = [];
  dataSource = new MatTableDataSource<OperationLead>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  customerStatus = 'AVAILABLE';
  filters?: FiltersInterface = { status: this.customerStatus };

  displayedColumns: string[] = [
    'select',
    'download',
    'registerDrawee',
    'sacado',
    'broker',
    'OperationType',
    'Parcela',
    'dueDateEnd',
    'type',
    'numberInvoice',
    'totalAmount',
    'valueLiquid',
  ];
  data!: OperationLead[];
  row!: OperationLead;
  selection = new SelectionModel<OperationLead>(true, []);
  totalFaceValue: number = 0;

  pageIndex: number = 0;
  pageSize: number = 20;
  full: boolean = true;

  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }
  getTotalFaceValue() {
    this.customerDashboardService.getTotalFaceValue(this.filters).subscribe({
      next: (totalFaceValue) => {
        this.totalFaceValue = totalFaceValue;
      },
    });
  }

  filterData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.leads.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.leads);
  }

  checkboxLabel(row: OperationLead): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      this.dataSource.data.indexOf(row) + 1
    }`;
  }
  openDialog() {
    const currentOperations = this.selection.selected;
    const dialogRef = this.dialog.open(SimulateReceivablesModalComponent, {
      data: { currentOperations },
    });
  }
  constructor(
    private customerDashboardService: customerDashboardService,
    public dialog: MatDialog,

    private _snackBar: MatSnackBar,
    private notifierService: NotifierService
  ) {}

  ngOnInit(): void {
    this.getCustomers();
  }

  ngAfterViewInit() {}

  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.customerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      });
  }
  lastPage = false;

  getCustomers(data?: FiltersInterface) {
    if (data) {
      this.filters = {
        status: this.customerStatus,
        ...data,
      };
    }

    if (!this.lastPage) {
      this.isLoading = true;
      this.customerDashboardService
        .getAll(this.pageIndex, this.pageSize, this.filters)
        .pipe(
          map(({ content, ...rest }) => {
            const invoicesWithWrongInstallments =
              this.checkInvoicesWithWrongInstallments(content);

            if (invoicesWithWrongInstallments?.length) {
              invoicesWithWrongInstallments.forEach((invoiceNumber) => {
                content = content.map((invoice) => {
                  return this.correctInvoiceInstallments(
                    invoice,
                    invoiceNumber
                  );
                });
              });
            }
            return { content, ...rest };
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (data) => {
            if (this.pageIndex === 0) this.leads = data.content;
            else this.leads = this.leads.concat(data.content);
            if (!data.last) this.pageIndex = this.pageIndex + 1;
            this.lastPage = data.last;
            this.getTotalFaceValue();
          },
        });
    }
  }

  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }

  clearSelection() {
    this.selection.clear();
  }
  handleSubmit(data?: FiltersInterface) {
    this.pageIndex = 0;
    this.lastPage = false;
    this.getCustomers(data);
  }

  openDialogRegisterDrawee(row: any) {
    this.dialog.open(RegisterDraweeModalComponent, {
      data: { sacadoId: row.sacadoId },
    });
  }

  checkInvoicesWithWrongInstallments(
    invoice: OperationLead[]
  ): OperationLead['numberInvoice'][] {
    return invoice
      .filter((invoice) => {
        const installmentAsString = String(invoice.numberInstallment);
        const hasWrongInstallment =
          installmentAsString.startsWith('0') &&
          installmentAsString.charAt(1) === '/';
        return hasWrongInstallment;
      })
      .map((invoice) => invoice.numberInvoice);
  }

  correctInvoiceInstallments(
    invoice: OperationLead,
    invoiceNumber: OperationLead['numberInvoice']
  ) {
    if (invoice.numberInvoice === invoiceNumber) {
      const numberInstallmentAsString = String(invoice.numberInstallment);
      const [firstHalf, secondHalf] = numberInstallmentAsString.split('/');
      const correctNumber = +firstHalf + 1;
      const correctInstallmentNumber = String(correctNumber).concat(
        '/',
        secondHalf
      );
      return { ...invoice, numberInstallment: correctInstallmentNumber };
    }
    return { ...invoice };
  }
}
