import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRestrictDigits]'
})
export class RestrictDigitsDirective {
  private previous = '';

  constructor(private el: ElementRef,private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const inputElement = this.el.nativeElement;
    const value = inputElement.value.replace(/[^0-9]/g, '');
    let cursorPos = inputElement.selectionStart;


    this.renderer.setProperty(inputElement, 'value', this.applyMask(value));

    cursorPos = this.getCursorPosition(cursorPos, value);
    inputElement.setSelectionRange(cursorPos, cursorPos);

    this.previous = value;
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent): void {
    const inputElement = this.el.nativeElement;
    const value = inputElement.value.replace(/[^0-9]/g, '');

    this.renderer.setProperty(inputElement, 'value', this.applyMask(this.previous));
  }


  private getCursorPosition(cursorPos: number, value: string): number {

    if(this.previous.length > value.length) {
      return cursorPos;
    }

    if(cursorPos === 3 || cursorPos === 6) {
      cursorPos = cursorPos+2;
    }

    return cursorPos;
  }

  private applyMask(value: string): string {
    if (value.length > 2 && value.length <= 4) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    } else if (value.length > 4) {
      value = value.substring(0, 2) + '/' + value.substring(2, 4) + '/' + value.substring(4, 8);
    }
    value = value.substring(0, 10);

    return value;
  }
}
