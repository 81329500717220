<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center">
            <span class="me-2"> Importar NF e documentos adicionais </span>
          </div>
          <span *ngIf="_documents?.length" class="text-success"
            >{{ _documents?.length }} documento(s) importado(s)</span
          >
        </div>
        <div>
          <button
            mat-stroked-button
            color="accent"
            (click)="fileInput.click()"
            type="button"
            [ngStyle]="{ 'button-disabled': _disabled }"
            [disabled]="_disabled"
            [class.spinner]="isLoading"
          >
            <span *ngIf="!isLoading">Importar</span>
            <input
              accept="application/pdf"
              [disabled]="isLoading"
              class="d-none"
              type="file"
              #fileInput
              multiple
              (change)="onChangeDoc($event)"
            />
          </button>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div
      class="d-flex align-items-center"
      *ngFor="let defaultDocument of _defaultDocuments; let i = index"
    >
      <button
        mat-icon-button
        color="warn"
        *ngIf="defaultDocument.deletable"
        (click)="deleteDefaultDocument(defaultDocument.id, i)"
      >
        <mat-icon>delete</mat-icon>
      </button>

      <span>{{ defaultDocument.name }}</span>
    </div>

    <div
      class="d-flex align-items-center"
      *ngFor="let document of _documents; let i = index"
    >
      <button
        mat-icon-button
        color="warn"
        *ngIf="document.deletable"
        (click)="deleteDocument(i)"
      >
        <mat-icon>delete</mat-icon>
      </button>

      <span>{{ document.file.name }}</span>
    </div>
  </mat-expansion-panel>
</mat-accordion>
