import { AccordionDocument, PartnerDocs } from "../interfaces/register-drawee.interface";

export class RegisterDrawee {
  static AccordionDocumentList(): AccordionDocument[]{
    return [
      {
         files: [],
         description: 'Contrato Social consolidado da empresa',
         isRequired: true,
         isLoading: false,
         type: 'CONTRATO_SOCIAL'
      },
      {
        files: [],
         description: 'Um comprovante de Endereço com menos de 90 dias',
         isRequired: true,
         isLoading: false,
         type: 'ENDERECO'
      },
      {
        files: [],
         description: 'Fotos da fachada da empresa',
         isRequired: true,
         isLoading: false,
         type: 'FACHADA_EMPRESA'
      },
      {
        files: [],
         description: 'Fotos internas da empresa (estoque, produção, administrativo)',
         isRequired: true,
         isLoading: false,
         type: 'INTERIOR_EMPRESA'
      },
      {
        files: [],
         description: 'Declaração de Faturamento dos últimos 12 meses assinado pelo contador da empresa',
         isRequired: true,
         isLoading: false,
         type: 'FATURAMENTO'
      },
      {
        files: [],
         description: 'Último Balanço Patrimonial ou Extrato do Simples',
         isRequired: false,
         isLoading: false,
         type: 'ULTIMO_BALANCO'
      },
      {
        files: [],
         description: 'DRE',
         isRequired: false,
         isLoading: false,
         type: 'DRE'
      },
      {
        files: [],
         description: 'Outros',
         isRequired: false,
         isLoading: false,
         type: 'OTHERS'
      }
   ]
  }

  static partnerDocumentList(): PartnerDocs[]{
    return [
      {
        files: [],
        description: 'CNH ou RG ou Identificação em entidade reconhecida',
        isRequired: true,
        isLoading: false,
        type: 'IDENTIFICACAO'
      },
      {
        files: [],
        description: 'Um comprovante de Endereço com menos de 90 dias',
        isRequired: true,
        isLoading: false,
        type: 'ENDERECO'
      }
    ]
  }
}
