import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { debounceTime, forkJoin } from 'rxjs';
import { Bank } from 'src/app/interfaces/register-drawee.interface';
import { RegisterDraweeService } from 'src/app/services/register-drawee.service';

import { BankList } from './../../../utils/bank-list';
import { NotifierService } from 'src/app/services/notifier.service';

enum ActivitySector{
  AGRO_INDUSTRIA = "Agroindústria",
  COMERCIO = "Comércio",
  INDUSTRIA = "Indústria",
  PRODUTOR_RURAL = "Produtor",
  SERVICO = "Serviços",
}

enum TaxRegimes{
  SIMPLES_NACIONAL = "Simples Nacional",
  LUCRO_PRESUMIDO= "Lucro Presumido",
  LUCRO_REAL = "Lucro Real",
  ISENTO = "Isento",
}

enum UnitTypes {
  HEAD_OFFICE = "Matriz",
  BRANCH = "Filial",
}

enum Banks{

}

@Component({
  selector: 'app-register-drawee-company',
  templateUrl: './register-drawee-company.component.html',
  styleUrls: ['./register-drawee-company.component.scss']
})
export class RegisterDraweeCompanyComponent implements OnInit{
  formCompany!: FormGroup;
  formBank!: FormGroup;
  editMode:boolean = false;
  bankList:Bank[] = [];
  @Output() validation = new EventEmitter<boolean>();


  activitySectorRef = ActivitySector;
  taxRegimesRef = TaxRegimes;
  unitTypesRef = UnitTypes;

  activityOptions!: (keyof typeof ActivitySector)[];
  regimeOptions!: (keyof typeof TaxRegimes)[];
  unitOptions!: (keyof typeof UnitTypes)[];
  bankOptions:any[] = BankList.list();
  @Output() saving = new EventEmitter<boolean>();
  isLoading = true;
  hasError = false;


  constructor(
    private registerDraweeService:RegisterDraweeService,
    private notifierService: NotifierService,
  ){
    this.activityOptions = Object.keys(this.activitySectorRef) as (keyof typeof ActivitySector)[];
    this.regimeOptions = Object.keys(this.taxRegimesRef) as (keyof typeof TaxRegimes)[];
    this.unitOptions = Object.keys(this.unitTypesRef) as (keyof typeof UnitTypes)[];

    this.constructForms();
  }

  ngOnInit(): void {
    this.getData();
  }

  constructForms(){
    this.formCompany = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      tradeName: new FormControl(null, Validators.required),
      cnpj: new FormControl(null, Validators.required),
      activity: new FormControl(null, Validators.required),
      sharedCapital: new FormControl(null, Validators.required),
      annualBilling: new FormControl(null, Validators.required),
      phone: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      legalNatureCode: new FormControl(null, Validators.required),
      openedOn: new FormControl(null, Validators.required),
      unit: new FormControl(null, Validators.required),
      regime: new FormControl(null, Validators.required),
    });


    this.formBank = new FormGroup({
      name: new FormControl(null, Validators.required),
      agency: new FormControl(null, Validators.required),
      account: new FormControl(null, Validators.required),
      accountDigit: new FormControl(null, Validators.required),
      pix: new FormControl(null),
      purpose: new FormControl('REFERENCE'),
      type: new FormControl('CORRENTE'),
    });
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  getData(){
    this.isLoading = true;

    const companyObservable = this.registerDraweeService.getCompany();
    const bankObservable = this.registerDraweeService.getBanks();

    forkJoin([companyObservable,bankObservable]).subscribe({
      next: ([company, bankList]) => {
        this.formCompany.patchValue({
          "id": company.id,
          "name": company.name,
          "tradeName": company.tradeName,
          "cnpj": company.cnpj,
          "activity": company.activity,
          "sharedCapital": company.sharedCapital,
          "annualBilling": company.annualBilling,
          "phone": company.phone,
          "legalNatureCode": company.legalNatureCode,
          "openedOn": company.openedOn,
          "unit": company.unit,
          "regime": company.regime,
        });

        this.bankList = bankList;

        this.save();
        this.isValid();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.hasError = true;

        this.notifierService.getDataError();
      }
    })
  }




  addBank(formDirective: FormGroupDirective){
    if(!this.formBank.valid) return;

    const newBank: Bank = {
      name: this.formBank.value.name.ShortName,
      agency: this.formBank.value.agency,
      account: this.formBank.value.account,
      accountDigit: this.formBank.value.accountDigit,
      pix: this.formBank.value.pix,
      purpose: this.formBank.value.purpose,
      type: this.formBank.value.type,
      code: this.formBank.value.name.COMPE,
    }

    this.registerDraweeService.addBank(newBank).subscribe({
      next: () => {
        formDirective.resetForm();
        this.formBank.reset({
          purpose: 'REFERENCE',
          type: 'CORRENTE'
        });

        this.notifierService.addBankSuccess();

        this.refreshBanks();
      },
      error: () => {
        this.notifierService.addBankError();
      }
    });
  }

  deleteBank(id: string){
    this.registerDraweeService.deleteBank(id).subscribe({
      next: () => {
        this.notifierService.dellBankSuccess();
        this.refreshBanks();
      },
      error: (err) => {
        this.notifierService.dellBankError();
      }
    });
  }

  save(){
    this.formCompany.valueChanges
    .pipe(
      debounceTime(1500),
    )
    .subscribe(forms => {

      delete forms['banks'];
      this.emitSaving(true);
      this.registerDraweeService.editCompany(forms).subscribe({
        next: () => {
         this.emitSaving(false);
        },
        error:() => {
          this.notifierService.saveError();
        }
      })
      this.isValid();
    });
  }

  private refreshBanks(){
    this.registerDraweeService.getBanks()
      .subscribe(bankList => {
        this.bankList = bankList;
        this.isValid()
      });
  }

  isValid(){
    this.validation.emit(this.formCompany.valid && !!this.bankList.length);
  }

  emitSaving(bool:boolean){
    this.saving.emit(bool)
  }
}
