import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { take, exhaustMap, catchError, retry } from 'rxjs/operators';
import { UserInterface } from '../interfaces/user';
import { AuthService } from '../services/auth.service';
import { customerDashboardService } from '../services/customer-dashboard.service';
import { NotifierService } from '../services/notifier.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private notifierService: NotifierService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> | any {
    const token = sessionStorage.getItem('auth');
    const auth = token ? JSON.parse(token) : null;

    const skipIntercept = req.headers.has('skip');

    if (req.url.includes(environment.register_api)) {
      return next.handle(req);
    }
    if (skipIntercept) {
      const modifiedReq = req.clone({
        headers: req.headers.delete('skip'),
      });
      return next.handle(modifiedReq);
    }

    if (req.url.includes('api.register.dev.broadfactor.com')) {
      /* NOT UP IN GITHUB */
      return next.handle(req);
    }

    const modifiedReq = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.code,
      },
    });

    return next.handle(modifiedReq).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          if (error.error.message === 'INVALID_TOKEN') {
            this.router.navigate(['/not-authorized']);
          }
          const message = error.error.customMessage ?? error.error.message;
          this.notifierService.showNotification({
            message: 'Erro ao salvar/exibir as alterações.',
            messageType: 'errorNotifier',
            alternative: 'Contate o time de operações!',
          });
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(error);
      })
    );
  }
}
