import { Component, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable, debounceTime, forkJoin } from 'rxjs';
import { Address, Contact, SocialNetwork } from 'src/app/interfaces/register-drawee.interface';
import { NotifierService } from 'src/app/services/notifier.service';
import { RegisterDraweeService } from 'src/app/services/register-drawee.service';
import { FormValidations } from 'src/app/utils/form-validations';

@Component({
  selector: 'app-register-drawee-contact',
  templateUrl: './register-drawee-contact.component.html',
  styleUrls: ['./register-drawee-contact.component.scss']
})
export class RegisterDraweeContactComponent {
  formContact!:FormGroup;
  formPhone!: FormGroup;
  editMode:boolean = false;
  contactList: Contact[] = [];
  @Output() validation = new EventEmitter<boolean>();
  @Output() saving = new EventEmitter<boolean>();
  isLoading = true;
  hasError = false;

  constructor(
    private formBuilder: FormBuilder,
    private draweeService: RegisterDraweeService,
    private notifierService: NotifierService
  ){
    this.contructForms();
    this.getData();

  }

  contructForms(){
    this.formContact = this.formBuilder.group({
      address: this.formBuilder.group({
        id: [null],
        postalCode: [null, Validators.required],
        street: [null, Validators.required],
        number: [null, Validators.required],
        additionalInformation: [null, Validators.required],
        district: [null, Validators.required],
        city: [null, Validators.required],
        state: [null, Validators.required],
      }),

      socialNetwork: this.formBuilder.group({
        id: [null],
        website: [null],
        facebook: [null],
        instagram: [null],
        linkedIn: [null],
      }),

      phones: this.formBuilder.array([], FormValidations.minLengthArray(1))
    });

    this.formPhone = this.formBuilder.group({
      name: [null, Validators.required],
      phone: [null, Validators.required],
      mail: [null, Validators.required],
    })
  }

  getData(){
    this.isLoading = true;

    const addressObservable: Observable<Address> = this.draweeService.getCompany('address');
    const socialNetworkObservable: Observable<SocialNetwork>  = this.draweeService.getCompany('socialNetwork');
    const contactObservable: Observable<Contact[]>  = this.draweeService.getCompany('contact');

    forkJoin([addressObservable,socialNetworkObservable,contactObservable]).subscribe({
      next:([address, socialNetwork, contacts]) => {
        this.formContact.get('address')?.patchValue({
          contructFormsid: address.id,
          postalCode: address.postalCode,
          street: address.street,
          number : address.number,
          additionalInformation: address.additionalInformation,
          district: address.district,
          city: address.city,
          state: address.state,
        });
        this.saveAddress();

        this.formContact.get('socialNetwork')?.patchValue({
          website: socialNetwork.website,
          facebook: socialNetwork.facebook,
          instagram: socialNetwork.instagram,
          linkedIn: socialNetwork.linkedIn,
        });
        this.saveSocialNetwork();

        const phonesFormArray = this.formContact.get('phones') as FormArray;
        contacts.forEach(contact => {
          phonesFormArray.push(this.formBuilder.group({
            name: contact.name,
            agency: contact.phone,
            mail: contact.mail
          }));
        });
        this.contactList = contacts;

        this.isValid();
        this.isLoading = false;
      },

      error: () => {
        this.isLoading = false;
        this.hasError = true;
        this.notifierService.getDataError();
      }
    })
  }

  saveAddress(){
    this.formContact.get('address')?.valueChanges
    .pipe(
      debounceTime(3000),
    )
    .subscribe(forms => {
      this.emitSaving(true);
      this.draweeService.editCompany(forms, 'address').subscribe({
        next: () => {
          this.isLoading = false;
          this.emitSaving(false);
        },
        error: () => {
          this.isLoading = false;
          this.emitSaving(false);

          this.notifierService.saveError();
        }
      })
      this.isValid()
    });
  }

  saveSocialNetwork(){
    this.formContact.get('socialNetwork')?.valueChanges
    .pipe(
      debounceTime(3000),
    )
    .subscribe(forms => {
      this.emitSaving(true);
      this.draweeService.editCompany(forms, 'socialNetwork').subscribe({
        next: () => {
          this.emitSaving(false);
        },

        error: () => {
          this.isLoading = false;
          this.emitSaving(false);

          this.notifierService.saveError();
        }
      })
      this.isValid()
    });
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }


  addPhone(formDirective:FormGroupDirective){
    if(!this.formPhone.valid) return ;

    const newPhone = {
      name: this.formPhone.value.name,
      phone: this.formPhone.value.phone,
      mail:this.formPhone.value.mail,
    };

    this.draweeService.addPhone(newPhone).subscribe({
      next: () => {
        formDirective.resetForm();
        this.formContact.get('formPhone')?.reset();
        this.refreshPhones();

        this.notifierService.addContactSuccess();
      },
      error: () => {
        this.notifierService.addContactError();
      }
    });
  }

  deletePhone(id: string){
    this.draweeService.deletePhone(id).subscribe({
      next: () => {
        this.notifierService.dellContactSuccess();
        this.refreshPhones();
      },
      error: (err) => {
        this.notifierService.dellContactError();
      }
    });
  }

  refreshPhones(){
    this.draweeService.getCompany('contact').subscribe({
      next: (contacts: Contact[]) => {
        const phonesFormArray = this.formContact.get('phones') as FormArray;
        contacts.forEach(contact => {
          phonesFormArray.push(this.formBuilder.group({
            name: contact.name,
            agency: contact.phone,
            mail: contact.mail
          }));
        });

        this.contactList = contacts;

        this.isValid()
      }
    })
  }

  isValid(){
    this.validation.emit(this.formContact.valid);
  }

  emitSaving(bool:boolean){
    this.saving.emit(bool)
  }
}
