import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StepByStepModalComponent } from 'src/app/components/step-by-step-modal/step-by-step-modal.component';
import { CreditInfoInterface } from 'src/app/interfaces/creditInfo.interface';

@Component({
  selector: 'app-home',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
})
export class OperationsComponent {
  creditInfo!: CreditInfoInterface;

  customerStatusOptions = {
    available: 'available',
    for_sale: 'for_sale',
    in_analysis: 'IN_ANALYSIS',
    documents: 'DOCUMENTS',
    paid: 'PAID',
    settled: 'SETTLED',
    in_collection: 'IN_COLLECTION',
  };
  constructor(public dialog: MatDialog) {}
  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }

  openDialog() {
    this.dialog.open(StepByStepModalComponent, {
      minWidth: '100vw',
    });
  }
}
