import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AditivoModalComponent } from './components/aditivo-modal/aditivo-modal.component';
import { AnalysisTitlesViewComponent } from './components/analysis-titles-view/analysis-titles-view.component';
import { AnticipationRealizedModalComponent } from './components/anticipation-realized-modal/anticipation-realized-modal.component';
import { DisponiblesTitlesComponent } from './components/disponibles-titles/disponibles-titles.component';
import { DocumentsTitlesComponent } from './components/documents-titles/documents-titles.component';
import { FiltersComponent } from './components/filters/filters.component';
import { ForSaleComponent } from './components/for-sale/for-sale.component';
import { InsufficientCreditModalComponent } from './components/insufficient-credit-modal/insufficient-credit-modal.component';
import { SignatureDocumentsModalComponent } from './components/signature-documents-modal/signature-documents-modal.component';
import { SimulateReceivablesModalComponent } from './components/simulate-receivables-modal/simulate-receivables-modal.component';
import { StatusViewComponent } from './components/status-view/status-view.component';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { AngularMaterialModule } from './modules/angular-material.module';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { OperationsComponent } from './pages/operations/operations.component';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AsideComponent } from './components/aside/aside.component';
import { ProcessStepsModalComponent } from './components/process-steps-modal/process-steps-modal.component';
import { StepByStepModalComponent } from './components/step-by-step-modal/step-by-step-modal.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { PartnerModalComponent } from './components/partner-modal/partner-modal.component';
import { RegisterDraweeModalComponent } from './components/register-drawee-modal/register-drawee-modal.component';
import { RegisterDraweeCompanyComponent } from './components/register-drawee-tabs/register-drawee-company/register-drawee-company.component';
import { RegisterDraweeContactComponent } from './components/register-drawee-tabs/register-drawee-contact/register-drawee-contact.component';
import { RegisterDraweeDocumentsComponent } from './components/register-drawee-tabs/register-drawee-documents/register-drawee-documents.component';
import { RegisterDraweePartnerComponent } from './components/register-drawee-tabs/register-drawee-partner/register-drawee-partner.component';
import { MatDrawerComponent } from './components/mat-drawer/mat-drawer.component';
import { NoteRegistrationCompletedModalComponent } from './components/note-registration-completed-modal/note-registration-completed-modal.component';
import { NotifierComponent } from './components/notifier/notifier.component';
import { RegisteredTitlesComponent } from './components/registered-titles/registered-titles.component';
import { InvoiceModule } from './modules/invoice/invoice.module';
import { InputMaskModule } from '@ngneat/input-mask';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import 'moment/locale/pt-br';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { TitleTypePipe } from './pipes/translate-titles-type.pipe';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    OperationsComponent,
    StatusViewComponent,
    SimulateReceivablesModalComponent,
    InsufficientCreditModalComponent,
    AnticipationRealizedModalComponent,
    SignatureDocumentsModalComponent,
    AnalysisTitlesViewComponent,
    DisponiblesTitlesComponent,
    ForSaleComponent,
    DocumentsTitlesComponent,
    FiltersComponent,
    NotFoundPageComponent,
    NotAuthorizedComponent,
    AditivoModalComponent,
    AsideComponent,
    ProcessStepsModalComponent,
    StepByStepModalComponent,
    NotifierComponent,
    RegisteredTitlesComponent,
    MatDrawerComponent,
    RegisterDraweeModalComponent,
    RegisterDraweeCompanyComponent,
    RegisterDraweeContactComponent,
    RegisterDraweeDocumentsComponent,
    RegisterDraweePartnerComponent,
    PartnerModalComponent,
    NoteRegistrationCompletedModalComponent,
    TitleTypePipe,
  ],
  imports: [
    InvoiceModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    InfiniteScrollModule,
    NgxMaskDirective,
    CurrencyMaskModule,
    InputMaskModule,
    NgxSkeletonLoaderModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },

    CurrencyPipe,
    provideNgxMask({}),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
