<div mat-dialog-content>
  <div class="main-container">
    <div class="image-container">
      <img
        src="../../../assets/png/Group 2206.png"
        alt="nota cadastrada com sucesso"
      />
    </div>
    <div class="description-container">
      <p class="label-container">Nota(s) Fiscal(is) cadastrada(s)!</p>
      <p class="label">
        Após a análise seus títulos ficarão disponíveis para compra!
      </p>
    </div>
    <div class="button-finish">
      <button mat-flat-button class="finish" [mat-dialog-close]>Ok</button>
    </div>
  </div>
</div>
