import { Component, OnInit } from '@angular/core';
import { StepByStepModalComponent } from '../step-by-step-modal/step-by-step-modal.component';
import { CreditInfoInterface } from 'src/app/interfaces/creditInfo.interface';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mat-drawer',
  templateUrl: './mat-drawer.component.html',
  styleUrls: ['./mat-drawer.component.scss'],
})
export class MatDrawerComponent {
  constructor(public authService: AuthService, public dialog: MatDialog) {}

  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }

  openDialog() {
    this.dialog.open(StepByStepModalComponent, {
      minWidth: '100vw',
    });
  }

  isFocused(route: string): boolean {
    return true;
  }

  handleNavigate(route: string) {}

  getButtonStyle(route: string) {
    if (this.isFocused(route)) {
      return {
        'background-color': '#cc3179',
      };
    }

    return null;
  }
}
