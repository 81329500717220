<header class="header">
  <h2 class="header-title">
    Como funciona o processo de solicitação de antecipação de notas fiscais de
    serviço
  </h2>
  <img
    src="assets/svg/Broadfactor Logo - Colorido [Letra preta].svg"
    alt="Logo da Broadfactor conjuntamento com a ContaAzul"
    class="header-img"
  />
  <img
    src="assets/svg/close.svg"
    alt="Icone de Fechar"
    mat-dialog-close
    style="cursor: pointer"
  />
</header>

<mat-dialog-content class="mat-typography container">
  <p class="container-description">
    O processo se divide em 4 etapas. Veja o que acontece em cada uma delas
    abaixo:
  </p>

  <section class="container-main">
    <mat-card class="container-main-card" *ngFor="let step of dataSteps">
      <mat-card-header class="container-main-card-header">
        <h1 class="container-main-card-header-title">{{ step.title }}</h1>
        <h3 class="container-main-card-header-subtitle">
          | {{ step.subtitle }}
        </h3>
      </mat-card-header>

      <figure class="container-main-card-image">
        <img mat-card-image [src]="step.img" alt="#" />
      </figure>

      <mat-card-content class="container-main-card-content">
        <p class="container-main-card-content-mainDescription">
          {{ step.shortDescription }}
        </p>
        <div
          class="container-main-card-content-item"
          *ngFor="let topic of step.description"
        >
          <div class="container-main-card-content-item-icon">
            <mat-icon>check</mat-icon>
          </div>
          <p [innerHTML]="formatDescription(topic)"></p>
        </div>
      </mat-card-content>
    </mat-card>
  </section>
</mat-dialog-content>
