import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import * as moment from 'moment';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { Output, EventEmitter } from '@angular/core';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { debounceTime, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/utils/dateAdapter';
import { maskCnpj } from 'src/app/utils/string-to-cnpj-mask';
import { DateValidator } from 'src/app/validators/date.validator';
import { NotifierService } from 'src/app/services/notifier.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})

export class FiltersComponent {
  formFilters!: FormGroup;
  @Output() filterEvent = new EventEmitter<FiltersInterface>();
  @Input() tabStatus!: string | null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private destroy$ = new Subject<void>();

  constructor(
    private customerDashboardService: customerDashboardService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService
  ) {
    this.formFilters = this.formBuilder.group({
      nameActorOne: [''],
      numberInvoice: [''],
      dateRange: this.formBuilder.group(
        {
          start: [null],
          end: [null],
        },
        { validators: DateValidator.validateDueDatePicker('start', 'end') }
      )
    });

    this.formFilters.controls['nameActorOne'].valueChanges
      .pipe(
        debounceTime(500),
        switchMap((data: string): any => {
          if (
            data?.trimStart() !== '' &&
            data?.trimEnd() !== '' &&
            data?.length > 2
          ) {
            this.getSearchFomento('ACTOR_ONE', this.removeSpecialChars(data));
          }

          return of('');
        })
      )
      .subscribe();

       // Atualiza a validade do grupo de data sempre que os campos de data mudarem
      this.formFilters.get('dateRange.start')?.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.formFilters.get('dateRange')?.updateValueAndValidity({emitEvent:true});
        });

      this.formFilters.get('dateRange.end')?.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.formFilters.get('dateRange')?.updateValueAndValidity();
        });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeSpecialChars(text: string) {
    return text.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9\s]/g, '');
  }
  formatedCnpjLabel(cnpj: string) {
    return maskCnpj(cnpj);
  }

  actorClientOptions: Record<
    string,
    { name: string; cnpj: string; id: string }[]
  > = {
    actorOne: [],
  };
  displayActorOne(cnpj?: string) {
    if (cnpj) {
      const { name } = this.actorClientOptions['actorOne'].find(
        (_: any) => _.cnpj === cnpj
      )!;

      return `${name} - ${maskCnpj(cnpj)}`;
    }

    return '';
  }

  getSearchFomento(field: 'ACTOR_ONE' | 'ACTOR_TWO' | 'ALL', filter?: string) {
    let type = this.formFilters.get('typeActorOne')?.value;
    if (field === 'ACTOR_TWO') {
      type = this.formFilters.get('typeActorTwo')?.value;
    }

    this.customerDashboardService
      .getSearchFomento(type, filter)
      .pipe(take(1))
      .subscribe((res) => {
        if (field === 'ACTOR_ONE') {
          this.actorClientOptions = {
            ...this.actorClientOptions,
            actorOne: res.content,
          };
        }
        if (field === 'ACTOR_TWO') {
          this.actorClientOptions = {
            ...this.actorClientOptions,
            actorTwo: res.content,
          };
        } else {
          this.actorClientOptions = {
            actorOne: res.content,
            actorTwo: res.content,
          };
        }
      });
  }

  getFormatedForm() {
    this.formFilters.controls['dateRange'].errors;
    const data = this.formFilters.value;
    data['companyList'] = [];

    if (data['dateRange'].start && data['dateRange'].end) {
      data['dueDateInit'] = moment(data['dateRange'].start).format('YYYY-MM-DD');
      data['dueDateEnd'] = moment(data['dateRange'].end).format('YYYY-MM-DD');
    }


    // Check if field is number (CNPJ)
    data['companyList'].push({
      cnpj: data.nameActorOne ?? null,
      type: 'SACADO',
    });
    const { nameActorOne, nameActorTwo, typeActorOne, typeActorTwo, dateRange, ...rest } =
      data;

    return rest;
  }

  clearFilters() {
    this.formFilters.reset({ status: this.tabStatus });
    this.onSubmit();
  }
  onSubmit() {
    if(this.formFilters.invalid) {

      this.notifier.showNotification({
        message: 'Campo inválido!',
        messageType: 'errorNotifier',
        alternative: 'Por favor, complete todas as informações necessárias.',
      });

      return;
    }
    const data = this.getFormatedForm();
    this.filterEvent.emit(data);
  }

  errorMessage(): string {
    const error = this.formFilters.get('dateRange')?.errors;

    if (error?.['startFieldRequired'])
      return 'Data inicial é obrigatória.';

    if (error?.['endFieldRequired'])
      return 'Data final é obrigatória.';

    if (error?.['startDateGreaterThanEndDate'])
      return 'Data inicial posterior a data final.';

    return 'Campo inválido!';
  }
}
