import { Component } from '@angular/core';

@Component({
  selector: 'app-step-by-step-modal',
  templateUrl: './step-by-step-modal.component.html',
  styleUrls: ['./step-by-step-modal.component.scss']
})
export class StepByStepModalComponent {
  dataSteps = DATA_STEP;
  

  formatDescription(description: string): string {
    const formattedDescription = description.replace('Brycloud', '<strong>Brycloud</strong>');

    return formattedDescription;
  }
}


export const DATA_STEP = [
  {
    title: '1º Passo',
    subtitle: 'Solicitar Antecipação',
    description:["Selecione as notas que deseja antecipar", 
                  "Clique em simular antecipação", 
                  "Clique em confirmar na modal que irá aparecer após a simulação"
                ],
    img: "assets/png/Frame.png",
    shortDescription: "Selecione as notas que deseja antecipar"
  }, 
  {
    title: '2º Passo',
    subtitle: 'À Venda',
    description:["Aqui você não precisa fazer nada. Basta aguardar até que as mesmas sejam enviadas para análise.",
                 "Nesta etapa você não precisa fazer nada, apenas aguardar que as notas que você selecionou sejam compradas e fiquem disponíveis na tela->Em análise"
                ],
    img: "assets/png/Payment-User.png",
    shortDescription: "Aguarde o processo de compra das notas"
  },
  {
    title: '3º Passo',
    subtitle: 'Em Análise',
    description:[
                 "O sacado (o seu cliente) irá receber um email informando-o da antecipação.",
                 "O sacado precisa responder o email com um (ok) para darmos sequência no processo da antecipação."

                ],
    img: "assets/png/Email.png",
    shortDescription:"O comprador das notas antecipadas irá notificar o sacado (o seu cliente)"
  },
  {
    title: '4º Passo',
    subtitle: 'Assinar Documento',
    description:["Sua empresa irá receber um email da empresa Brycloud com o link para assinatura dos documentos.",
                 "Clique no link e siga o passo a passo.",
                 "Assine os documentos.",
                 "Processo é finalizado e em até 24 horas o dinheiro estará na conta."
                ],
    img: "assets/svg/Sign-Contract.svg",
    shortDescription: "Assinatura dos documentos"
  },

]