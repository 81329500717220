import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UpdateDraweeAddressInterface } from '../interfaces/address.interface';
import { CreateAddressContactInterface } from '../interfaces/contact.interface';
import {
  CreateDraweeInterface,
  InvoiceInterface,
} from '../interfaces/drawee.interface';
import { InstallmentInterface } from '../interfaces/invoice.interface';
import { CompanyInterface } from 'src/app/interfaces/company.interface';
import { Bank } from 'src/app/interfaces/register-drawee.interface';
import { InvoiceInstallmentsBankInterface } from '../interfaces/invoiceInstallmentsBank.interface';

@Injectable({
  providedIn: 'root',
})
export class DraweeService {
  url = environment.register_api;
  invoice = new BehaviorSubject<InvoiceInterface | null>(null);
  invoiceDocument = new BehaviorSubject<File | null>(null);
  registeredDrawee = new BehaviorSubject<CompanyInterface | null>(null);
  registeredInvoices = new BehaviorSubject<string[]>([]);
  invoiceSaved = new BehaviorSubject<boolean>(false);
  retrySubmit = new BehaviorSubject<boolean>(false);
  canAddInvoice = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getInvoice() {
    return this.invoice.asObservable();
  }

  getDraweeByInvoice(document: any) {
    const form = new FormData();
    form.append('file', document);

    const url = environment.operation_api + `api/invoice/document/xml`;
    return this.http.post<InvoiceInterface>(url, form, {
      headers: {
        skip: 'true',
        media: 'true',
        Authorization: `Bearer ${environment.operation_api_token}`,
      },
    });
  }

  createDrawee(data: CreateDraweeInterface) {
    const url = this.url + `open/register/SACADO`;
    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => form.append(key, value));

    return this.http.post<string>(url, form);
  }
  updateDraweePhoneAndMail(id: string, data: { mail: string; phone: string }) {
    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => form.append(key, value));
    const url =
      environment.register_api + `secure-api/company/${id}/mailAndPhone/`;
    return this.http.post(url, form);
  }

  addContact(draweeId: string, data: CreateAddressContactInterface) {
    const url = this.url + `secure-api/company/${draweeId}/contact/`;
    return this.http.post(url, data);
  }

  deleteContact(draweeId: string, contactId: string) {
    const url =
      this.url + `secure-api/company/${draweeId}/contact/${contactId}`;
    return this.http.delete(url);
  }

  deleteDoc(id: string) {
    const url =
      environment.operation_api + `api/invoice/document/deleted/${id} `;
    return this.http.delete(url, {
      headers: {
        skip: 'true',
        Authorization: `Bearer ${environment.operation_api_token}`,
      },
    });
  }

  updateAddress(draweeId: string, address: UpdateDraweeAddressInterface) {
    const url = this.url + `secure-api/company/${draweeId}/address/`;
    return this.http.put(url, address);
  }

  uploadDocument(invoiceId: string, document: File) {
    const url =
      environment.operation_api + `api/invoice/document/add/${invoiceId}`;
    const form = new FormData();
    const type = document.name.split('.').pop()!.toUpperCase();
    form.append('file', document);
    form.append('type', type);

    return this.http.post(url, form, {
      headers: {
        skip: 'true',
        Authorization: `Bearer ${environment.operation_api_token}`,
      },
    });
  }

  createInvoice(data: InstallmentInterface) {
    const url = environment.operation_api + 'api/invoice_installments/add';

    return this.http.post<string>(url, data, {
      headers: {
        skip: 'true',
        Authorization: `Bearer ${environment.operation_api_token}`,
      },
    });
  }

  getBanks(draweeId: string) {
    const url =
      environment.register_api + `secure-api/company/${draweeId}/banks`;
    return this.http.get<Bank[]>(url);
  }

  addBank(draweeId: string, bank: Bank) {
    const url = this.url + `secure-api/company/${draweeId}/banks/`;

    return this.http.post<Bank>(url, bank);
  }
  getInvoiceById(id: string) {
    const url = environment.operation_api + `api/invoice_installments/${id} `;
    return this.http.get<InvoiceInterface>(url, {
      headers: {
        skip: 'true',
        Authorization: `Bearer ${environment.operation_api_token}`,
      },
    });
  }

  addInvoiceInstallmentsBank(data: InvoiceInstallmentsBankInterface) {
    const url = environment.operation_api + `api/invoice_installments/add/bank`;
    return this.http.post(url, data, {
      headers: {
        skip: 'true',
        Authorization: `Bearer ${environment.operation_api_token}`,
      },
    });
  }
}
