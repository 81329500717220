import { Component } from '@angular/core';

@Component({
  selector: 'app-note-registration-completed-modal',
  templateUrl: './note-registration-completed-modal.component.html',
  styleUrls: ['./note-registration-completed-modal.component.scss']
})
export class NoteRegistrationCompletedModalComponent {

}
