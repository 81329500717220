import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { AngularMaterialModule } from '../angular-material.module';
import { AddInvoiceModalComponent } from './components/add-invoice-modal/add-invoice-modal.component';
import { DraweeFormComponent } from './components/drawee-form/drawee-form.component';
import { InvoiceDataFormComponent } from './components/invoice-data-form/invoice-data-form.component';
import { InvoiceFormComponent } from './components/invoice-form/invoice-form.component';
import { ReceiptDataComponent } from './components/receipt-data/receipt-data.component';
import { InvoiceRoutingModule } from './invoice-routing.module';
import { AutocompleteFilterComponent } from 'src/app/components/autocomplete-filter/autocomplete-filter.component';
import { InvoiceFormScreenComponent } from './components/invoice-form-screen/invoice-form-screen.component';
import { ExitRegisterDialogComponent } from './components/exit-register-dialog/exit-register-dialog.component';
import { DocumentDropdownComponent } from './components/document-dropdown/document-dropdown.component';
import { DeleteModalInstallmentComponent } from './components/delete-modal-installment/delete-modal-installment.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [
    InvoiceDataFormComponent,
    ReceiptDataComponent,
    DraweeFormComponent,
    InvoiceFormComponent,
    AddInvoiceModalComponent,
    AutocompleteFilterComponent,
    InvoiceFormScreenComponent,
    ExitRegisterDialogComponent,
    DocumentDropdownComponent,
    DeleteModalInstallmentComponent,
  ],
  imports: [
    CommonModule,
    InvoiceRoutingModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    InputMaskModule,
    NgxMaskDirective,
  ],
})
export class InvoiceModule {}
