<div class="container" *ngIf="(!isLoading && !hasError); else loadingOrError">
  <form class="p-4 pb-0" [formGroup]="formContact">
    <!-- Address Section -->
    <section formGroupName="address">
      <div class="d-flex justify-content-between" >
        <h2 class="color-std-title">Endereço</h2>
        <button type="button" mat-flat-button color="accent" (click)="toggleEditMode()">
          <mat-icon class="mat-18">mode_edit</mat-icon>
          <span>Editar</span>
        </button>
      </div>

      <div class="row">
        <mat-form-field appearance="outline" class="col-md-3" >
          <mat-label>CEP</mat-label>
          <input matInput
            required
            formControlName="postalCode"
            [ngClass]="{'not-allow-edit': !editMode}"
            [readonly]="!editMode"
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md">
          <mat-label>Logradouro</mat-label>
          <input matInput required formControlName="street"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Número</mat-label>
          <input matInput required formControlName="number"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Complemento</mat-label>
          <input matInput required formControlName="additionalInformation"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Bairro</mat-label>
          <input matInput required formControlName="district"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Cidade</mat-label>
          <input matInput required formControlName="city"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>UF</mat-label>
          <input matInput required formControlName="state"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>
      </div>
    </section>

    <!-- Social Network Section -->
    <section formGroupName="socialNetwork">
      <h2 class="color-std-title">Redes Sociais</h2>
      <div class="row">
        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Site</mat-label>
          <input matInput formControlName="website"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Facebook</mat-label>
          <input matInput formControlName="facebook"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Instagram</mat-label>
          <input matInput formControlName="instagram"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>LinkedIn</mat-label>
          <input matInput formControlName="linkedIn"
            [readonly]="!editMode"
            [ngClass]="{'not-allow-edit': !editMode}"
          >
        </mat-form-field>
      </div>
    </section>
  </form>

  <!-- Phones Section -->
  <form [formGroup]="formPhone" class="p-4 pt-0" #formDirective="ngForm" (ngSubmit)="addPhone(formDirective)">
    <h2 class="color-std-title">Contato</h2>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>Nome</mat-label>
        <input matInput required formControlName="name"
          [readonly]="!editMode"
          [ngClass]="{'not-allow-edit': !editMode}"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>Telefone</mat-label>
        <input matInput required formControlName="phone"
          [readonly]="!editMode"
          [ngClass]="{'not-allow-edit': !editMode}"
          mask="(00) 000000009"
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>E-mail</mat-label>
        <input matInput required formControlName="mail"
          [readonly]="!editMode"
          [ngClass]="{'not-allow-edit': !editMode}"
        >
      </mat-form-field>
      <button
        color="accent"
        mat-stroked-button
        class="col-md-2 p-1"
        style="height: 56px;"
        type="submit"
        [disabled]="!editMode"
        [ngClass]="{'not-allow-edit': !editMode}"
      >
        <mat-icon class="mat-18">add</mat-icon>
        <span><b>Adicionar</b></span>
      </button>
    </div>

    <div class="row" *ngFor="let contact of contactList">
      <mat-form-field  appearance="outline" class="col-md-3">
        <mat-label>Nome</mat-label>
        <input matInput disabled [value]="contact.name">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>Telefone</mat-label>
        <input matInput  disabled [value]="contact.phone">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>E-mail</mat-label>
        <input matInput  disabled [value]="contact.mail">
      </mat-form-field>
      <button
        color="warn"
        mat-stroked-button
        class="col-md-2 p-1"
        style="height: 56px;"
        type="button"
        (click)="deletePhone(contact.id)"
        [disabled]="!editMode"
        [ngClass]="{'not-allow-edit': !editMode}"
      >
        <mat-icon class="mat-18">delete</mat-icon>
        <span><b>Remover</b></span>
      </button>
    </div>
  </form>
</div>

<ng-template #loadingOrError>
  <ng-container *ngIf="isLoading; else error">
    <div class="spinner-container-std">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </ng-container>

  <ng-template #error>
    <div class="d-flex justify-content-center align-items-center">
      <img src="assets/svg/error.svg" alt="Imagem representando a ocorrência de erro"
      class="img-std-error">
    </div>
  </ng-template>
</ng-template>
