import { AbstractControl } from '@angular/forms';

export class CnpjValidator {
  static valid(control: AbstractControl): any {
    const BLACKLIST: Array<string> = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
      '12345678909',
    ];

    const cnpj = control.value?.replace(/\D/g, '');

    if (!cnpj) {
      return {
        valid: false,
        message: 'CPNJ inválido.',
      };
    }

    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;

    if (!cnpj) {
      return {
        valid: false,
        message: 'CPNJ Inválido.',
      };
    }
    if (cnpj.length !== 14) {
      return {
        valid: false,
        message: 'CPNJ Inválido.',
      };
    }
    if (BLACKLIST.includes(cnpj)) {
      return {
        valid: false,
        message: 'CPNJ Inválido.',
      };
    }

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== Number(digits.charAt(0))) {
      return {
        valid: false,
        message: 'CPNJ Inválido.',
      };
    }

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let k = size; k >= 1; k--) {
      sum += Number(numbers.charAt(size - k)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== Number(digits.charAt(1))) {
      return {
        valid: false,
        message: 'CPNJ Inválido.',
      };
    }

    return null;
  }
}
