<div class="main">
  <form [formGroup]="form">
    <div class="module-form" formGroupName="invoice">
      <mat-form-field appearance="outline">
        <mat-label>Número da nota</mat-label>
        <input matInput formControlName="number" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Selecione o tipo do Título</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let option of invoiceTypeOptions"
            [value]="option.value"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="showInvoiceNumber">
        <mat-label>Chave da Nota Fiscal</mat-label>
        <input matInput formControlName="key" />
      </mat-form-field>
    </div>

    <p class="description">
      <span class="description important">Documentos </span>Torne seus títulos
      mais atrativos! importe os comprovantes de entrega ou documentos
      adicionais.
    </p>

    <app-document-dropdown
      [disabled]="docsDisabled"
      [documents]="documents"
      [defaultDocuments]="defaultDocuments"
      (onChange)="onDocumentsChange($any($event))"
      (onDelete)="deleteDefaultDocument($any($event))"
    ></app-document-dropdown>

    <div>
      <p class="form-label">Parcelas</p>

      <div class="module-form" formGroupName="installment">
        <mat-form-field appearance="outline">
          <mat-label>Adicione o valor da parcela</mat-label>
          <input
            matInput
            formControlName="value"
            [inputMask]="currencyInputMask"
            placeholder="R$ 0.00"
          />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Data de vencimento</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button
          class="action-button"
          mat-flat-button
          (click)="addInstallment()"
          [disabled]="form.disabled || formSaved"
        >
          Adicionar
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <table class="installments-table">
      <thead>
        <tr>
          <th>Todas as Parcelas</th>
          <th>Vencimento</th>
          <th>Valor da parcela</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let installment of installments; let installmentIndex = index"
        >
          <td class="data-sacado">{{ installment.number }}</td>
          <td class="info-installment">
            {{ installment.date }}
          </td>
          <td class="info-installment">
            {{ installment.value | currency : "BRL" }}
          </td>
          <td>
            <button
              mat-icon-button
              class="delete-installment"
              *ngIf="!formSaved && !form.disabled"
              (click)="onDeleteInstallment(installmentIndex)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <div class="actions-container">
    <div
      matTooltip="Verifique se todos os campos obrigatórios estão preenchidos corretamente."
      [matTooltipDisabled]="!formInvalid"
    >
      <button
        *ngIf="!formSaved"
        class="submit"
        mat-flat-button
        (click)="onSubmit()"
        [disabled]="formInvalid"
        [ngClass]="{ 'button-disabled': formInvalid }"
        [class.spinner]="isLoading"
      >
        <span *ngIf="!isLoading">Salvar</span>
      </button>
    </div>
  </div>
</div>
