<h2 mat-dialog-title>Adicionar Novo Sócio</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="formsPartner" class="p-2">
    <mat-form-field appearance="outline" class="w-100" >
      <mat-label>CPF/CNPJ</mat-label>
      <input matInput
        required
        formControlName="cpfCnpj"
        mask="000.000.000-00||00.000.000/0000-00"
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100" >
      <mat-label>Nome Completo</mat-label>
      <input matInput
        required
        formControlName="name"
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100" >
      <mat-label>Telefone</mat-label>
      <input matInput
        required
        formControlName="phone"
        mask="(00) 000000009"
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100" >
      <mat-label>E-mail</mat-label>
      <input matInput
        required
        formControlName="mail"
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100" >
      <mat-label>Qualificação</mat-label>
      <mat-select
            required
            formControlName="qualificationCode"
            placeholder="Selecione um item"

          >
            <mat-option
              *ngFor="let qualification of qualificationList"
              [value]="qualification.code"
            >
              {{qualification.code}} - {{ qualification.name }}
            </mat-option>
          </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="pe-4  pb-5" >
  <button mat-stroked-button color="accent" mat-dialog-close>Cancel</button>
  <button mat-flat-button cdkFocusInitial (click)="save()" color="accent" >
    <mat-icon class="mat-18">add</mat-icon>
    <span>Adicionar</span>
  </button>
</mat-dialog-actions>
