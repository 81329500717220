import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";

export class DateValidator {
  static validateDueDatePicker(start: string, end: string): ValidationErrors | null {

    const validator = (control: AbstractControl) => {
      if (!start || !end) {
        throw new Error('start and end are required');
      }

      if (!control.root || !(<FormGroup>control.root).controls) {
        return null;
      }

      const startField = control.get(start)?.value;
      const endField = control.get(end)?.value;

      if (startField && !endField) {
        control.get(start)?.setErrors({ endFieldRequired: true });
        return { endFieldRequired: true };
      }

      if (!startField && endField) {
        control.get(end)?.setErrors({ startFieldRequired: true });
        return { startFieldRequired: true };
      }

      if(startField && endField && startField > endField) {
        control.get(start)?.setErrors({ startDateGreaterThanEndDate: true });
        control.get(end)?.setErrors({ startDateGreaterThanEndDate: true });
        return { startDateGreaterThanEndDate: true };
      }

      control.get(start)?.setErrors(null);
      control.get(end)?.setErrors(null);

      return null;
    }

    return validator;
  }
}
