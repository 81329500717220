<div class="container">
  <header class="d-flex justify-content-between align-items-center mx-5 pt-4 h-20" >
    <h2 class="mb-0">
      Cadastro do Sacado
    </h2>
    <button mat-icon-button cdkFocusInitial [mat-dialog-close]="true" aria-label="Fechar">
      <mat-icon>close</mat-icon>
    </button>

  </header>

  <div class="h-60">
    <mat-tab-group color="accent">
        <mat-tab *ngFor="let tab of tabs">
            <ng-template  mat-tab-label>
                <mat-icon [ngClass]="tab.isValid ? 'text-success' :'text-danger'"
                  class="me-2"
                >
                  {{ tab.isValid ? "check_circle" : "error" }}
                </mat-icon>
                <span class="text-sm-left text-dark">{{ tab.title }}</span>
            </ng-template>

            <ng-template matTabContent>
                <div [ngSwitch]="tab.title">
                    <app-register-drawee-company
                      *ngSwitchCase="tabsEnum.company"
                      (validation)="tab.isValid = $event;"
                      (saving)="isSavingData = $event"
                    ></app-register-drawee-company>

                    <app-register-drawee-contact
                      *ngSwitchCase="tabsEnum.contact"
                      (validation)="tab.isValid = $event;"
                      (saving)="isSavingData = $event"
                    ></app-register-drawee-contact>

                    <app-register-drawee-partner
                      *ngSwitchCase="tabsEnum.partner"
                      (validation)="tab.isValid = $event"
                      (saving)="isSavingData = $event"
                    ></app-register-drawee-partner>

                    <app-register-drawee-documents
                      *ngSwitchCase="tabsEnum.documents"
                      (validation)="tab.isValid = $event"
                    ></app-register-drawee-documents>
                </div>

            </ng-template>
        </mat-tab>
    </mat-tab-group>
  </div>
  <footer align="center" class="footer">
    <div class="d-flex justify-content-center align-items-center" role="alert" *ngIf="isSavingData">
			<mat-spinner [diameter]="20" ></mat-spinner>
      <div> &nbsp;Salvando dados...</div>
		</div>
  </footer>
</div>
