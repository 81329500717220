import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  MatTableDataSource,
  MatTableDataSourcePaginator,
} from '@angular/material/table';
import { map, startWith, switchMap, take } from 'rxjs';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { AnticipationRealizedModalComponent } from '../anticipation-realized-modal/anticipation-realized-modal.component';
import { InsufficientCreditModalComponent } from '../insufficient-credit-modal/insufficient-credit-modal.component';
import { ProcessStepsModalComponent } from '../process-steps-modal/process-steps-modal.component';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-simulate-receivables-modal',
  templateUrl: './simulate-receivables-modal.component.html',
  styleUrls: ['./simulate-receivables-modal.component.scss'],
})
export class SimulateReceivablesModalComponent implements AfterViewInit {
  currentOperations!: OperationLead[];
  totalAmount!: number;
  netValue!: number;
  selection = new SelectionModel<OperationLead>(true, []);
  pageIndex: number = 0;
  leadSource = '';
  pageSize: number = 20;
  leadsService: any;
  pageEvent!: PageEvent;
  isLoading = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  customerStatus = 'AVAILABLE';
  leads!: OperationLead[];
  totalData!: number;
  discount!: number;
  emoluments!: number;
  totalNetValue!: number;
  amountToPay!: number;

  loadingAntecipation = false;

  amountPaid!: number;

  constructor(
    private customerDashboardService: customerDashboardService,
    public dialog: MatDialog,
    public notifierService: NotifierService,

    @Inject(MAT_DIALOG_DATA) public data: { currentOperations: OperationLead[] }
  ) {
    this.currentOperations = data.currentOperations;

    this.totalAmount = data.currentOperations.reduce(
      (total, current) => total + current.totalAmount,
      0
    );
    this.currentOperations = data.currentOperations;

    this.amountToPay = data.currentOperations.reduce(
      (total, current) => total + current.amountToPay,
      0
    );

    this.discount = this.totalAmount - this.amountToPay;
  }
  getEmoluments() {
    this.customerDashboardService
      .getEmoluments(this.currentOperations.map((operation) => operation.id))
      .subscribe((response) => {
        // valor líquido total
        // somatoria de amountToPay menos emolumentos

        this.emoluments = response.emoluments;
        this.totalNetValue =
          this.currentOperations.reduce(
            (total, current) => total + current.amountToPay,
            0
          ) - this.emoluments;
      });
  }
  ngAfterViewInit() {
    this.getEmoluments();
  }

  openDialogAntecipationRealized() {
    this.loadingAntecipation = true;

    const installments = this.currentOperations.map(
      (operation) => operation.id
    );
    const status = 'FOR_SALE';

    this.customerDashboardService
      .updateInstallments({ installments, status })
      .subscribe({
        next: (res) => {
          this.loadingAntecipation = false;
          this.notifierService.showNotification({
            message: 'Dados Alterados.',
            alternative: 'Titulo alterado com Sucesso!',
            messageType: 'successNotifier',
          });
          const dialogRef = this.dialog.open(ProcessStepsModalComponent, {
            minWidth: '900px',
            width: '60vw',
          });
          dialogRef.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        },
        error: (err: HttpErrorResponse) => {
          console.log(err, err.error);

          if (err.error.customMessage === 'INSUFFICIENT_CREDIT_LIMIT') {
            this.dialog.open(InsufficientCreditModalComponent);
          }
        },
      });
  }
}
