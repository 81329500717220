export class TypeTitleOptions {
  static list() {
    return [
      { name: 'CT-e', value: 'CTE' },
      { name: 'CHQ', value: 'CHQ' },
      { name: 'DSR', value: 'DSR' },
      { name: 'DMR', value: 'DMR' },
      { name: 'Contrato', value: 'CONTRACT' },
      { name: 'Empenho', value: 'EFFORT' },
    ];
  }
}
