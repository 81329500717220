import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { RegisterDrawee } from 'src/app/utils/register-drawee';

import { Document } from 'src/app/interfaces/register-drawee.interface';
import { RegisterDraweeService } from 'src/app/services/register-drawee.service';
import { NotifierService } from 'src/app/services/notifier.service';


@Component({
  selector: 'app-register-drawee-documents',
  templateUrl: './register-drawee-documents.component.html',
  styleUrls: ['./register-drawee-documents.component.scss']
})
export class RegisterDraweeDocumentsComponent implements OnInit{
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  accordionDocuments = RegisterDrawee.AccordionDocumentList(); /* NEET GET AND SET THE FILES */
  @Output() validation = new EventEmitter<boolean>();
  isLoading = true;
  hasError = false;
  editMode = false;

  constructor(
    private draweeService: RegisterDraweeService,
    private notifierService: NotifierService,
  ){}

  ngOnInit(): void {
    this.getDocuments();
  }

  getDocuments(){
    this.isLoading = true;
    this.draweeService.getCompany('document').subscribe({
      next: (documents) => {
        this.isLoading = false;
        this.pushDocuments(documents);
        this.validation.emit(this.isValid());
      },

      error: () => {
        this.isLoading = false;
        this.hasError = true;
        this.notifierService.getDataError();
      }
    });
  }

  addDocuments(event: any, type: any){
    const foundDoc =  this.accordionDocuments.find(doc => doc.type == type);
    foundDoc!.isLoading = true;

    const fileSelected = <FileList>event.srcElement.files;
    let setFile: Set<File> = new Set();

    for(let i = 0; i < fileSelected.length; i++){
      setFile.add(fileSelected[i]);
    }
    this.draweeService.addDocument(setFile, type).subscribe({
      next: () => {
        foundDoc!.isLoading = false;
        this.validation.emit(this.isValid());
        this.refreshDocs();
        this.notifierService.addDocSuccess();
      },
      error: () => {
        this.notifierService.addDocError();
      }
    });
  }

  deleteDocument(document:Document ){
   this.draweeService.deleteDocument(document.id).subscribe({
    next: () => {
      this.validation.emit(this.isValid());

      const indexType = this.accordionDocuments.findIndex(doc => doc.type == document.type);
      const index = this.accordionDocuments[indexType].files.findIndex(file => {
        file.id == document.id
      });

      this.accordionDocuments[indexType].files.splice(index, 1);

      this.notifierService.dellDocSuccess();
    },
    error: () => {
      this.notifierService.dellDocError();
    }
   });
  }


  pushDocuments(data: any) {
    if (data) {
      Object.entries(data).forEach((element: any) => {
        const currObj = this.accordionDocuments.find((x: any) => x.type === element[0]);
        if (currObj) {
          currObj.files = [];
          currObj.files.push(...element[1]);
        } else {
          // Encontrando o objeto 'OTHERS' dentro de accordionDocuments
          const othersObj = this.accordionDocuments.find((x: any) => x.type === 'OTHERS');
          if (othersObj) {
            othersObj.files = [];
            othersObj.files.push(...element[1]);
          }
        }
      });
    }
  }

  isValid():boolean{

    for(let i = 0; i < this.accordionDocuments.length; i++){
      if(this.accordionDocuments[i].isRequired &&
        !this.accordionDocuments[i].files.length
       ) return false;
    }

    return true;
  }

  private refreshDocs(){
    this.getDocuments();
  }

  toggleEditMode(){
    this.editMode = !this.editMode;
  }
}
