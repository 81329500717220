<div class="main">
  <div
    class="autocomplete-filter-container"
    *ngIf="canFilterDrawee && !formSaved"
  >
    <app-autocomplete-filter
      (selected)="setSelectedDrawee($event)"
      notFoundLabel="Sacado não encontrado. Preencha os dados manualmente"
    ></app-autocomplete-filter>

    <button
      *ngIf="!formSaved"
      class="filter-submit"
      mat-flat-button
      (click)="submitSelectedDrawee()"
      [disabled]="isFetchingDrawee"
    >
      <span>Preencher dados</span>
    </button>
  </div>

  <p class="description">
    <span class="description important"
      ><mat-icon>warning</mat-icon>Importante! </span
    >Aumente suas chances de venda completando o cadastro do sacado.
  </p>

  <form [formGroup]="form">
    <mat-progress-bar
      *ngIf="isFetchingDrawee || isFetchingAddress"
      mode="indeterminate"
    >
    </mat-progress-bar>
    <div>
      <p class="form-label">Dados do Sacado</p>
      <div class="module-form" formGroupName="drawee">
        <mat-form-field appearance="outline">
          <mat-label>CNPJ</mat-label>
          <input matInput formControlName="cnpj" mask="00.000.000/0000-00" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="drawee-content">
          <mat-label>Razão Social</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Telefone</mat-label>
          <input
            matInput
            formControlName="phone"
            mask="(00) 00000-0000||(00) 0000-0000"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="drawee-content">
          <mat-label>E-mail</mat-label>
          <input matInput formControlName="mail" />
        </mat-form-field>
      </div>
    </div>

    <div>
      <p class="form-label">Endereço do Sacado</p>
      <div class="module-form" formGroupName="drawee">
        <div class="container-address">
          <div class="address-content">
            <mat-form-field appearance="outline">
              <mat-label>CEP</mat-label>
              <input matInput formControlName="postalCode" mask="00000-000" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="drawee-content">
              <mat-label>Logradouro</mat-label>
              <input matInput formControlName="street" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Número</mat-label>
              <input matInput formControlName="number" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="drawee-content">
              <mat-label>Complemento</mat-label>
              <input matInput formControlName="additionalInformation" />
            </mat-form-field>
          </div>
          <div class="address-content">
            <mat-form-field appearance="outline" class="drawee-content">
              <mat-label>Bairro</mat-label>
              <input matInput formControlName="district" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Cidade</mat-label>
              <input matInput formControlName="city" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>UF</mat-label>
              <input matInput formControlName="state" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div formArrayName="contacts">
      <p class="form-label">Contatos</p>

      <div *ngFor="let contact of contacts.controls; let i = index">
        <p class="form-label" *ngIf="i > 0">Contato {{ i }}</p>

        <div class="module-form" [formGroupName]="i">
          <mat-form-field appearance="outline" class="drawee-content">
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Telefone</mat-label>
            <input matInput formControlName="phone" mask="(00) 00000-0000" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="drawee-content">
            <mat-label>E-mail</mat-label>
            <input matInput formControlName="mail" />
          </mat-form-field>
          <button
            class="action-button"
            mat-flat-button
            (click)="addContact()"
            *ngIf="i === 0"
            [disabled]="!form.get('contacts')?.enabled || contact?.invalid"
          >
            Adicionar
            <mat-icon>add</mat-icon>
          </button>
          <button
            class="remove-contact-button"
            mat-icon-button
            (click)="removeContact(i)"
            *ngIf="i > 0 && !formSaved"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="actions-container">
    <div
      matTooltip="Verifique se todos os campos obrigatórios estão preenchidos corretamente."
      [matTooltipDisabled]="!formInvalid"
    >
      <button
        *ngIf="!hideSubmit || this.form.touched"
        class="submit"
        mat-flat-button
        (click)="onSubmit()"
        [disabled]="formInvalid"
        [ngClass]="{ 'button-disabled': formInvalid }"
        [class.spinner]="isSavingDrawee"
      >
        <span *ngIf="!isSavingDrawee">Salvar</span>
      </button>
    </div>
  </div>
</div>
