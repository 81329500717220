<mat-drawer-container class="example-container">
  <mat-drawer
    #drawer
    class="example-sidenav"
    mode="side"
    [opened]="authService.isAuthenticated"
  >
    <div class="container">
      <div class="content-container">
        <img
          class="logo"
          src="../assets/svg/Broadfactor Logo - Colorido [Letra preta].svg"
          alt="logo"
        />
        <div class="button-container">
          <button
            mat-flat-button
            class="button-operations"
            (click)="handleNavigate('/')"
            [ngStyle]="getButtonStyle('/')"
          >
            <mat-icon>shopping_cart</mat-icon>Operações
          </button>
        </div>
      </div>
      <div class="informations-container">
        <div class="credit-informations-card">
          <div class="credit-informations-card">
            <div class="outline">
              <p class="small-text">Taxa</p>
              <p class="value">
                {{
                  getPercentInfo(authService.creditInfo?.discountFactor!)
                    | percent : "1.0-2"
                }}
              </p>
            </div>
          </div>
          <hr />
          <div class="outline">
            <p class="small-text">Limite de Crédito</p>
            <p class="value">
              {{ authService.creditInfo?.creditLimit | currency : "BRL" }}
            </p>
          </div>
        </div>
        <hr />
        <div class="credit-informations-card">
          <div class="outline">
            <p class="small-text">Limite utilizado</p>
            <p class="value">
              {{ authService.creditInfo?.usedCreditLimit | currency : "BRL" }}
            </p>
          </div>
        </div>
        <hr />
        <div class="credit-informations-card">
          <div class="outline">
            <p class="small-text credit-available">Limite Disponível</p>
            <p class="value credit-available">
              {{ authService.creditInfo?.remainingCredit | currency : "BRL" }}
            </p>
          </div>
        </div>

        <div class="container-comoFunciona" (click)="openDialog()">
          <button class="container-comoFunciona-btn">Como Funciona</button>
          <img
            src="assets/svg/external-link.svg"
            alt="Link Exerno Icon"
            class="container-comoFunciona-icon"
          />
        </div>
      </div>
      <div class="spacer"></div>
    </div>
  </mat-drawer>

  <div class="content-container">
    <ng-content></ng-content>
  </div>
</mat-drawer-container>
