export class QualificationList {

  static list() {
      return [
          {code: '00', name: 'Representante legal'},
          {code: '05', name: 'Administrador'},
          {code: '08', name: 'Conselheiro de Administração'},
          {code: '10', name: 'Diretor'},
          {code: '16', name: 'Presidente'},
          {code: '17', name: 'Procurador'},
          {code: '20', name: 'Sociedade Consorciada'},
          {code: '21', name: 'Sociedade Filiada'},
          {code: '22', name: 'Sócio'},
          {code: '23', name: 'Sócio Capitalista'},
          {code: '24', name: 'Sócio Comanditado'},
          {code: '25', name: 'Sócio Comanditário'},
          {code: '26', name: 'Sócio de Indústria'},
          {code: '28', name: 'Sócio-Gerente'},
          {code: '29', name: 'Sócio Incapaz ou Relat.Incapaz (exceto menor)'},
          {code: '30', name: 'Sócio Menor (Assistido/Representado)'},
          {code: '31', name: 'Sócio Ostensivo'},
          {code: '37', name: 'Sócio Pessoa Jurídica Domiciliado no Exterior'},
          {code: '38', name: 'Sócio Pessoa Física Residente no Exterior'},
          {code: '47', name: 'Sócio Pessoa Física Residente no Brasil'},
          {code: '48', name: 'Sócio Pessoa Jurídica Domiciliado no Brasil'},
          {code: '49', name: 'Sócio-Administrador'},
          {code: '52', name: 'Sócio com Capital'},
          {code: '53', name: 'Sócio sem Capital'},
          {code: '54', name: 'Fundador'},
          {code: '55', name: 'Sócio Comanditado Residente no Exterior'},
          {code: '56', name: 'Sócio Comanditário Pessoa Física Residente no Exterior'},
          {code: '57', name: 'Sócio Comanditário Pessoa Jurídica Domiciliado no Exterior'},
          {code: '58', name: 'Sócio Comanditário Incapaz'},
          {code: '59', name: 'Produtor Rural'},
          {code: '63', name: 'Cotas em Tesouraria'},
          {code: '65', name: 'Titular Pessoa Física Residente ou Domiciliado no Brasil'},
          {code: '66', name: 'Titular Pessoa Física Residente ou Domiciliado no Exterior'},
          {code: '67', name: 'Titular Pessoa Física Incapaz ou Relativamente Incapaz (exceto menor)'},
          {code: '68', name: 'Titular Pessoa Física Menor (Assistido/Representado)'},
          {code: '70', name: 'Administrador Residente ou Domiciliado no Exterior'},
          {code: '71', name: 'Conselheiro de Administração Residente ou Domiciliado no Exterior'},
          {code: '72', name: 'Diretor Residente ou Domiciliado no Exterior'},
          {code: '73', name: 'Presidente Residente ou Domiciliado no Exterior'},
          {code: '74', name: 'Sócio-Administrador Residente ou Domiciliado no Exterior'},
          {code: '75', name: 'Fundador Residente ou Domiciliado no Exterior'},
        ]
  }
}
