import { Component, OnDestroy } from '@angular/core';
import { DraweeService } from '../../services/drawee.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
})
export class InvoiceFormComponent implements OnDestroy {
  draweeName = '';
  draweeExpanded = true;
  invoiceExpanded = true;
  draweeFormSaved = false;
  formSaved = false;
  previousInvoiceLength = 0;

  constructor(private draweeService: DraweeService) {
    this.listenRetrySubmitChanges();
    this.listenFormSavedChanges();
  }

  listenRetrySubmitChanges() {
    this.draweeService.retrySubmit.subscribe({
      next: (value) => {
        if (value && !this.formSaved) {
          this.draweeFormSaved = false;
          this.handleOpenExpansionPanel('drawee');
        }
      },
    });
  }

  listenFormSavedChanges() {
    this.draweeService.invoiceSaved.pipe(take(2)).subscribe({
      next: (invoiceSaved) => {
        if (invoiceSaved) this.formSaved = invoiceSaved;
      },
    });
  }

  onRegisteredDrawee(result: boolean) {
    if (!result) return;

    this.handleOpenExpansionPanel('invoice');
    this.draweeFormSaved = true;
  }

  onRegisteredInvoice(result: boolean) {
    if (!result) return;

    this.invoiceExpanded = false;
    this.draweeExpanded = false;
    this.formSaved = true;
  }

  ngOnDestroy() {
    this.draweeService.invoice.next(null);
  }

  draweeNameChanges(value: string) {
    this.draweeName = value;
  }

  handleOpenExpansionPanel(form: 'drawee' | 'invoice') {
    if (form === 'drawee') {
      this.draweeExpanded = true;
      this.invoiceExpanded = false;
    } else {
      this.draweeExpanded = false;
      this.invoiceExpanded = true;
    }
  }
}
