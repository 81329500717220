import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, switchMap, takeWhile } from 'rxjs';
import { CompanyInterface } from 'src/app/interfaces/company.interface';
import { FilteredCompanyInterface } from 'src/app/interfaces/filtered-company.interface';
import { CepService } from 'src/app/services/cep.service';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { CnpjValidator } from 'src/app/validators/cnpj.validator';
import { UpdateDraweeAddressInterface } from '../../interfaces/address.interface';
import { CreateDraweeInterface } from '../../interfaces/drawee.interface';
import { DraweeService } from '../../services/drawee.service';

@Component({
  selector: 'app-drawee-form',
  templateUrl: './drawee-form.component.html',
  styleUrls: ['./drawee-form.component.scss'],
})
export class DraweeFormComponent implements OnInit {
  form: FormGroup;
  isFetchingDrawee = false;
  isFetchingAddress = false;
  isSavingDrawee = false;
  canFetchAddress = true;
  canFilterDrawee = true;
  previousCnpj = '';
  contactsToRemove: string[] = [];
  selectedDrawee?: FilteredCompanyInterface;
  drawee?: CompanyInterface;
  formSaved = false;
  invoiceSaved = false;
  hideSubmit = false;
  saveDrawee = false;

  @Output() draweeNameChanges = new EventEmitter<string>();
  @Output() registered = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private customerService: customerDashboardService,
    private draweeService: DraweeService,
    private cepService: CepService
  ) {
    this.form = this.fb.group({
      drawee: this.fb.group({
        cnpj: ['', [CnpjValidator.valid]],
        name: [{ value: '', disabled: true }, [Validators.required]],
        phone: [{ value: '', disabled: true }, [Validators.required]],
        mail: [{ value: '', disabled: true }, [Validators.required]],
        postalCode: [{ value: '', disabled: true }, [Validators.required]],
        street: [{ value: '', disabled: true }, [Validators.required]],
        number: [{ value: '', disabled: true }],
        additionalInformation: [{ value: '', disabled: true }],
        district: [{ value: '', disabled: true }, [Validators.required]],
        city: [{ value: '', disabled: true }, [Validators.required]],
        state: [{ value: '', disabled: true }, [Validators.required]],
      }),
      contacts: this.fb.array([
        this.fb.group({
          name: [{ value: '', disabled: true }, [Validators.required]],
          phone: [{ value: '', disabled: true }, [Validators.required]],
          mail: [{ value: '', disabled: true }, [Validators.required]],
        }),
      ]),
    });

    this.listenCnpjChanges();
    this.listenDraweeNameChanges();
    this.listenPostalCodeChanges();
    this.listenInvoiceSaved();
    this.listenRetrySubmit();
  }

  ngOnInit(): void {
    this.draweeService
      .getInvoice()
      .pipe(takeWhile((invoice) => !!invoice))
      .subscribe((invoice) => {
        if (invoice) {
          this.saveDrawee = true;
          this.canFilterDrawee = false;
          this.form.controls['drawee'].patchValue({
            cnpj: invoice?.cnpjSacado,
          });
          this.form.controls['drawee'].get('cnpj')?.disable();
          if (invoice?.id) {
            this.hideSubmit = true;
            this.disableFields('XML');
          }
        }
      });
  }

  get contacts() {
    return this.form.get('contacts') as FormArray;
  }

  get formInvalid() {
    return (
      this.form.controls['drawee'].invalid ||
      this.isFetchingAddress ||
      this.contacts.controls?.length === 1
    );
  }

  listenCnpjChanges() {
    this.form.controls['drawee']
      .get('cnpj')
      ?.valueChanges.subscribe((value) => {
        if (
          this.form.controls['drawee'].get('cnpj')?.valid &&
          value !== this.previousCnpj
        ) {
          this.previousCnpj = value;
          this.form.controls['drawee'].enable();
          this.form.controls['contacts'].enable();

          this.getCompanyByCnpj(value);
        }
      });
  }

  listenDraweeNameChanges() {
    this.form.controls['drawee']
      .get('name')
      ?.valueChanges.subscribe((value) => {
        this.draweeNameChanges.emit(value);
      });
  }

  listenPostalCodeChanges() {
    this.form.controls['drawee']
      .get('postalCode')
      ?.valueChanges.subscribe((value) => {
        if (value?.length === 8 && this.canFetchAddress) {
          this.getAddressByPostalCode(value);
        }
        if (!this.canFetchAddress) this.canFetchAddress = true;
      });
  }

  listenInvoiceSaved() {
    this.draweeService.invoiceSaved.subscribe({
      next: (invoiceSaved) => {
        if (invoiceSaved) this.invoiceSaved = invoiceSaved;
      },
    });
  }

  listenRetrySubmit() {
    this.draweeService.retrySubmit.subscribe({
      next: (retrySubmit) => {
        if (retrySubmit && !this.invoiceSaved) {
          this.previousCnpj = '';
          this.canFilterDrawee = true;
          this.formSaved = false;
          this.form.reset();
          this.form.enable();
          this.contactsToRemove = [];
          this.contacts.clear();
          this.contacts.push(
            this.fb.group({
              name: ['', [Validators.required]],
              phone: ['', [Validators.required]],
              mail: ['', [Validators.required]],
            })
          );
        }
      },
    });
  }

  patchForm(company: CompanyInterface) {
    const formData = {
      name: company.name,
      phone: company.phoneInformed,
      mail: company.mailInformed,
      postalCode: company.address[0].postalCode,
      street: company.address[0].street,
      number: company.address[0].number,
      additionalInformation: company.address[0].additionalInformation,
      district: company.address[0].district,
      city: company.address[0].city,
      state: company.address[0].state,
    };
    this.form.controls['drawee'].patchValue(formData);
    this.contacts.clear();
    this.contacts.push(
      this.fb.group({
        name: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        mail: ['', [Validators.required]],
      })
    );

    company.contacts.forEach((contact) => {
      let { name, phone, mail, contact: contactValue, id, type } = contact;
      if (type === 'PHONE') phone = contactValue;
      else if (type === 'MAIL') mail = contactValue;

      this.contacts.push(
        this.fb.group({
          name: [{ value: name, disabled: true }],
          phone: [{ value: phone, disabled: true }],
          mail: [{ value: mail, disabled: true }],
          id: [{ value: id, disabled: true }],
        })
      );
    });
  }

  setSelectedDrawee(drawee: FilteredCompanyInterface) {
    this.selectedDrawee = drawee;
  }

  submitSelectedDrawee() {
    this.form.controls['drawee'].patchValue({
      cnpj: this.selectedDrawee?.cnpj,
    });
  }

  getCompanyByCnpj(cnpj: string) {
    this.isFetchingDrawee = true;
    this.canFetchAddress = false;
    this.customerService.getCompanyByCnpj(cnpj).subscribe({
      next: (company) => {
        this.patchForm(company);
        this.drawee = company;
        this.isFetchingDrawee = false;
        if (this.saveDrawee) this.draweeService.registeredDrawee.next(company);
      },
      error: () => {
        this.form.controls['drawee'].reset({
          cnpj: this.form.controls['drawee'].get('cnpj')?.value,
        });
        this.form.controls['contacts'].reset();
        this.isFetchingDrawee = false;
      },
    });
  }

  getAddressByPostalCode(postalCode: string) {
    this.isFetchingAddress = true;
    this.cepService.getAddressByPostalCode(postalCode).subscribe({
      next: (address) => {
        const formData = {
          street: address.logradouro,
          district: address.bairro,
          city: address.localidade,
          state: address.uf,
        };
        this.form.controls['drawee'].patchValue(formData);
        this.isFetchingAddress = false;
      },
      error: () => {
        this.isFetchingAddress = false;
      },
    });
  }

  addContact() {
    this.contacts.push(
      this.fb.group({
        name: [
          {
            value: this.contacts.controls[0].get('name')?.value,
            disabled: true,
          },
        ],
        phone: [
          {
            value: this.contacts.controls[0].get('phone')?.value,
            disabled: true,
          },
        ],
        mail: [
          {
            value: this.contacts.controls[0].get('mail')?.value,
            disabled: true,
          },
        ],
      })
    );

    this.contacts.controls[0].reset();
  }

  removeContact(index: number) {
    const contactId = this.contacts.controls[index].value?.id;
    // contact id can be saved to remove it from the database later
    // contact id is not retornig from the API, so this will not work in this moment
    if (contactId) this.contactsToRemove.push(contactId);
    this.contacts.removeAt(index);
  }

  createDrawee() {
    if (!this.formInvalid) return;

    const data: CreateDraweeInterface = {
      cnpj: this.form.controls['drawee'].get('cnpj')?.value,
      mail: this.form.controls['drawee'].get('mail')?.value,
      phone: this.form.controls['drawee'].get('phone')?.value,
    };

    return this.draweeService.createDrawee(data);
  }

  updateAddress(draweeId: string) {
    const data: UpdateDraweeAddressInterface = {
      postalCode: this.form.controls['drawee'].get('postalCode')?.value,
      city: this.form.controls['drawee'].get('city')?.value,
      number: this.form.controls['drawee'].get('number')?.value,
      additionalInformation: this.form.controls['drawee'].get(
        'additionalInformation'
      )?.value,
      street: this.form.controls['drawee'].get('street')?.value,
      district: this.form.controls['drawee'].get('district')?.value,
      state: this.form.controls['drawee'].get('state')?.value,
    };

    return this.draweeService.updateAddress(draweeId, data);
  }

  addContacts(draweeId: string) {
    return (
      this.contacts.controls
        // NÃO PODE ADICIONAR CONTATO COM ID, MAS API ESTÁ RETORNANDO ID NULL
        .filter((_, index) => index !== 0 && !_.value?.id)
        .map((contact) => {
          return this.draweeService.addContact(draweeId, contact.value);
        })
    );
  }

  removeContacts(draweeId: string) {
    return this.contactsToRemove.map((contactId) =>
      this.draweeService.deleteContact(draweeId, contactId)
    );
  }

  disableFields(registerType?: 'XML') {
    this.canFetchAddress = false;
    this.form.controls['drawee'].get('cnpj')?.disable();
    this.form.controls['drawee'].get('name')?.disable();
    if (registerType === 'XML') {
      this.form.controls['drawee'].get('phone')?.setValidators(null);
      this.form.controls['drawee'].get('mail')?.setValidators(null);
      return;
    }
  }

  onSubmit() {
    if (this.formInvalid) return;
    this.isSavingDrawee = true;

    const draweeId = this.drawee?.id;
    if (draweeId) {
      const mailAndPhone = {
        mail: this.form.controls['drawee'].get('mail')?.value,
        phone: this.form.controls['drawee'].get('phone')?.value,
      };
      const updatePhoneAndMail$ = this.draweeService.updateDraweePhoneAndMail(
        draweeId,
        mailAndPhone
      );
      const updateAddress$ = this.updateAddress(draweeId);
      const addContacts$ = this.addContacts(draweeId);
      const removeContacts$ = this.removeContacts(draweeId);

      forkJoin([updateAddress$, ...addContacts$, ...removeContacts$]).subscribe(
        // forkJoin([
        //   updatePhoneAndMail$,
        //   updateAddress$,
        //   ...removeContacts$,
        // ]).subscribe({
        {
          next: () => {
            this.disableFields();
            this.registered.emit(true);
            this.formSaved = true;
            this.draweeService.registeredDrawee.next(this.drawee!);

            this.isSavingDrawee = false;
          },
          error: () => {
            this.isSavingDrawee = false;
          },
        }
      );

      return;
    }

    const data: CreateDraweeInterface = {
      cnpj: this.form.controls['drawee'].get('cnpj')?.value,
      mail: this.form.controls['drawee'].get('mail')?.value,
      phone: this.form.controls['drawee'].get('phone')?.value,
    };

    this.draweeService
      .createDrawee(data)
      .pipe(
        switchMap((draweeId) => {
          const mailAndPhone = {
            mail: data.mail,
            phone: data.phone,
          };
          const updatePhoneAndMail$ =
            this.draweeService.updateDraweePhoneAndMail(draweeId, mailAndPhone);
          const updateAddress$ = this.updateAddress(draweeId);
          const addContacts$ = this.addContacts(draweeId);
          const registeredDrawee$ = this.customerService.getCompanyByCnpj(
            data.cnpj
          );

          return forkJoin([registeredDrawee$, updateAddress$, ...addContacts$]);
          // return forkJoin([
          //   registeredDrawee$,
          //   updateAddress$,
          //   updatePhoneAndMail$,
          // ]);
        })
      )
      .subscribe({
        next: (response) => {
          const drawee = response[0];
          this.draweeService.registeredDrawee.next(drawee);

          this.disableFields();
          this.formSaved = true;
          this.isSavingDrawee = false;
        },
        error: () => {
          this.isSavingDrawee = false;
        },
      });
  }
}
