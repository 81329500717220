<h1 mat-dialog-title class="modal-title">
  Sem crédito suficiente para antecipação!
</h1>
<div mat-dialog-content>
  <div class="current-operations-container">
    <div class="operation-image-container">
      <img
        src="./../../../assets/png/sem-limite 1.png"
        alt="sem limite para antecipação"
      />
    </div>

    <div class="operation-title-container">
      <p class="bold-text">
        Você não tem limite de crédito suficiente para esta antecipação.
      </p>
    </div>

    <div class="operation-description">
      <p class="description-text">
        Você pode complementar seus dados de cadastro para obter uma análise de
        crédito mais aprofundada e conseguir um limite mais adequado para a sua
        empresa.
      </p>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="close">Ok</button>
  </mat-dialog-actions>
</div>
