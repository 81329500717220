<h1 mat-dialog-title>
  Simulação de antecipação
  <label
    class="labelHeading"
    matTooltip="Simulação válida apenas hoje! O valor muda devido aos prazos de vencimento e quantidade de parcelas."
    matTooltipClass="example-tooltip-red1"
  >
    <mat-icon>help</mat-icon>
  </label>
</h1>
<div mat-dialog-content>
  <div class="current-operations-container">
    <div class="operation-title-container">
      <p class="title-selected">Títulos selecionados</p>
      <p class="title-selected">Valor de Face</p>
    </div>

    <div
      class="operation-title-container"
      *ngFor="let operation of currentOperations"
    >
      <p>{{ operation.nameSacado }}</p>
      <p>
        {{ operation.totalAmount | currency : "BRL" }}
      </p>
    </div>

    <hr />

    <div class="operation-title-container">
      <p class="title-selected">Valor total de face</p>
      <p class="title-selected">
        {{ totalAmount | currency : "BRL" }}
      </p>
    </div>
  </div>
  <div class="fee-container">
    <div class="text-fee">
      <p class="fee">(-) Deságio:</p>
      <p class="fee">{{ discount | currency : "BRL" }}</p>
    </div>
    <div class="text-fee">
      <div class="fee-label-container">
        <p class="fee">(-) Emolumentos:</p>
        <label
          matTooltip="R$ 10,00 por sacado, R$ 5,00 por título e R$ 10,00 por operação"
          ><mat-icon>help</mat-icon>
        </label>
      </div>
      <p class="fee">{{ emoluments | currency : "BRL" }}</p>
    </div>
  </div>
  <div class="operation-title-container result-value-container">
    <p class="title-selected">Valor líquido da antecipação</p>
    <p class="title-selected">{{ totalNetValue | currency : "BRL" }}</p>
  </div>
</div>

<mat-dialog-actions align="center">
  <button mat-stroked-button mat-dialog-close [disabled]="loadingAntecipation">
    Cancelar
  </button>
  <button
    mat-flat-button
    [mat-dialog-close]="true"
    class="button-simulate"
    (click)="openDialogAntecipationRealized()"
    [disabled]="loadingAntecipation"
  >
    Antecipar
  </button>
</mat-dialog-actions>
