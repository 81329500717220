<h1 mat-dialog-title class="modal-title">Assinatura de documentos</h1>
<div mat-dialog-content>
  <div class="current-operations-container">
    <div class="operation-image-container">
      <img
        src="./../../../assets/png/assinatura 1.png"
        alt="sem limite para antecipação"
      />
    </div>

    <div class="operation-title-container">
      <p class="bold-text">
        Após a análise você receberá um e-mail para a assinatura dos documentos
        na
        <span style="font-weight: 500"> plataforma BRy.</span>
      </p>
    </div>

    <div class="operation-description">
      <p class="description-text">
        É necessário se cadastrar uma única vez na plataforma, nas próximas
        antecipações basta fazer o login para assinar.
      </p>
      <p>Em seguida já poderá receber o valor da antecipação na sua conta.</p>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="accent">Ok</button>
  </mat-dialog-actions>
</div>
