<div mat-dialog-content>
  <div class="title-container">
    <h1>Cadastro de Nota Fiscal</h1>

    <p>
      É possível adicionar uma Nota Fiscal para venda através de um arquivo XML
      ou inserir os dados da nota manualmente.
    </p>
  </div>
  <div class="nf-upload-container">
    <div class="register-options-container">
      <mat-icon class="register-icon">upload</mat-icon>
      <p class="register-text">Cadastro por XML (NF de produto e CT-e)</p>
      <p>Importando o XML os dados necessários são salvos automaticamente</p>
      <div class="button-actions-container">
        <button
          mat-flat-button
          class="button-actions"
          (click)="fileInput.click()"
          [ngStyle]="{
            'background-color': '#CC3179'
          }"
          [disabled]="isLoading"
          [class.spinner]="isLoading"
        >
          <mat-icon *ngIf="!isLoading">upload</mat-icon>
          <span *ngIf="!isLoading">Importar NF-e</span>
          <input
            class="d-none"
            type="file"
            #fileInput
            accept="application/xml"
            (change)="addDocumentInvoice($event)"
          />
        </button>
      </div>
    </div>
    <div class="register-options-container">
      <mat-icon class="register-icon">edit</mat-icon>
      <p class="register-text">Cadastro Manual</p>
      <p>Caso não possua o XML, é possível cadastrar os dados manualmente.</p>
      <div class="button-actions-container">
        <button
          mat-flat-button
          class="button-actions"
          [ngStyle]="{
            'background-color': '#000'
          }"
          (click)="navigateTo('cadastro/dados-nota-fiscal')"
        >
          Cadastrar NF-e <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
