import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PostalCodeAddressInterface } from '../interfaces/postal-code-address.interface';

@Injectable({
  providedIn: 'root',
})
export class CepService {
  constructor(private http: HttpClient) {}

  getAddressByPostalCode(cep: string): Observable<PostalCodeAddressInterface> {
    const url = `https://viacep.com.br/ws/${cep}/json/`;
    return this.http.get<PostalCodeAddressInterface>(url);
  }
}
