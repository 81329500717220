import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { take, finalize, map } from 'rxjs';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { OperationTypeList } from 'src/app/utils/operation-type-list';
import { RegisterDraweeModalComponent } from '../register-drawee-modal/register-drawee-modal.component';

@Component({
  selector: 'app-for-sale',
  templateUrl: './for-sale.component.html',
  styleUrls: ['./for-sale.component.scss'],
})
export class ForSaleComponent implements OnInit {
  pageIndex: number = 0;
  pageSize: number = 20;
  totalFaceValue: number = 0;

  isLoading = false;
  leads: OperationLead[] = [];
  dataSource = new MatTableDataSource<OperationLead>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  customerStatus = 'FOR_SALE';
  filters?: FiltersInterface = { status: this.customerStatus };

  displayedColumns: string[] = [
    'download',
    'registerDrawee',
    'sacado',
    'broker',
    'OperationType',
    'Parcela',
    'dueDateEnd',
    'type',
    'numberInvoice',
    'totalAmount',
    'valueLiquid',
  ];
  constructor(
    private customerDashboardService: customerDashboardService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private notifierService: NotifierService
  ) {}
  lastPage = false;

  ngOnInit(): void {
    this.getCustomers();
  }

  ngAfterViewInit() {}

  getCustomers(data?: FiltersInterface, pageIndex?: number, pageSize?: number) {
    if (data) {
      this.filters = {
        status: this.customerStatus,
        ...data,
      };
    }

    if (!this.lastPage) {
      this.isLoading = true;

      this.customerDashboardService
        .getAll(this.pageIndex, this.pageSize, this.filters)
        .pipe(
          map(({ content, ...rest }) => {
            const invoicesWithWrongInstallments =
              this.checkInvoicesWithWrongInstallments(content);

            if (invoicesWithWrongInstallments?.length) {
              invoicesWithWrongInstallments.forEach((invoiceNumber) => {
                content = content.map((invoice) => {
                  return this.correctInvoiceInstallments(
                    invoice,
                    invoiceNumber
                  );
                });
              });
            }
            return { content, ...rest };
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (data) => {
            if (this.pageIndex === 0) this.leads = data.content;
            else this.leads = this.leads.concat(data.content);
            if (!data.last) this.pageIndex = this.pageIndex + 1;
            this.lastPage = data.last;
            this.getTotalFaceValue();
          },
        });
    }
  }
  getTotalFaceValue() {
    this.customerDashboardService.getTotalFaceValue(this.filters).subscribe({
      next: (totalFaceValue) => {
        this.totalFaceValue = totalFaceValue;
      },
    });
  }

  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }
  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.customerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      });
  }
  handleSubmit(data?: FiltersInterface) {
    this.pageIndex = 0;
    this.lastPage = false;
    this.getCustomers(data);
  }

  openDialogRegisterDrawee(row: any) {
    this.dialog.open(RegisterDraweeModalComponent, {
      data: { sacadoId: row.sacadoId },
    });
  }

  checkInvoicesWithWrongInstallments(
    invoice: OperationLead[]
  ): OperationLead['numberInvoice'][] {
    return invoice
      .filter((invoice) => {
        const installmentAsString = String(invoice.numberInstallment);
        const hasWrongInstallment =
          installmentAsString.startsWith('0') &&
          installmentAsString.charAt(1) === '/';
        return hasWrongInstallment;
      })
      .map((invoice) => invoice.numberInvoice);
  }

  correctInvoiceInstallments(
    invoice: OperationLead,
    invoiceNumber: OperationLead['numberInvoice']
  ) {
    if (invoice.numberInvoice === invoiceNumber) {
      const numberInstallmentAsString = String(invoice.numberInstallment);
      const [firstHalf, secondHalf] = numberInstallmentAsString.split('/');
      const correctNumber = +firstHalf + 1;
      const correctInstallmentNumber = String(correctNumber).concat(
        '/',
        secondHalf
      );
      return { ...invoice, numberInstallment: correctInstallmentNumber };
    }
    return { ...invoice };
  }
}
