<div class="main">
  <div class="title-container">
    <span>Confira os dados antes de salvar</span>
  </div>
  <div>
    <div class="container">
      <div class="content-description">
        <p class="title">
          {{ title }}
        </p>
      </div>

      <div class="cards-container">
        <div
          class="card-bank"
          (click)="selectBank(bank.id)"
          [ngClass]="{ 'selected-card': selectedBankId === bank.id }"
          *ngFor="let bank of banks"
        >
          <mat-icon>account_balance</mat-icon>
          <p class="text-weight">{{ bank.code }} - {{ bank.name }}</p>
          <p class="text-weight">
            Agência:
            <span class="text-secundary">{{ bank.agency }}</span>
          </p>
          <p class="text-weight">
            Conta: <span class="text-secundary">{{ bank.account }}</span>
          </p>
          <p class="text-weight">
            PIX: <span class="text-secundary">{{ bank.pix }}</span>
          </p>
        </div>
      </div>
      <div class="others-bank">
        <p class="text-weight">Receber em outra conta</p>
      </div>
      <form [formGroup]="form" class="options-banks">
        <mat-form-field appearance="outline" class="col-md">
          <mat-label>Banco</mat-label>
          <mat-select
            placeholder="Selecione um Banco"
            required
            formControlName="bank"
          >
            <mat-option [value]="bank" *ngFor="let bank of bankOptions">
              {{ bank.COMPE }} - {{ bank.ShortName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Agência</mat-label>
          <input formControlName="agency" matInput required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Conta Corrente</mat-label>
          <input formControlName="account" matInput required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Digito</mat-label>
          <input formControlName="accountDigit" matInput required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>PIX (Opcional)</mat-label>
          <input formControlName="pix" matInput />
        </mat-form-field>

        <button class="add-bank" mat-flat-button (click)="addAccount()">
          Adicionar
        </button>
      </form>
    </div>

    <div class="button-container">
      <button
        mat-flat-button
        class="button-save"
        (click)="saveForm()"
        [class.spinner]="isLoading"
      >
        <span *ngIf="!isLoading">Salvar</span>
      </button>
      <button mat-flat-button class="button-cancel" (click)="back.emit()">
        Voltar
      </button>
      <div class="spacer"></div>
    </div>
  </div>
</div>
