import { AbstractControl, ValidatorFn } from '@angular/forms';

export class FormValidations {
  static minLengthArray(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.length < minLength) {
        return { minLengthArray: { requiredLength: minLength, actualLength: control.value.length } };
      }
      return null;
    };
  }
}
