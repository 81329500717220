import { Address, Contact, PartnerInterface, Document} from './../../interfaces/register-drawee.interface';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Observable, forkJoin } from 'rxjs';
import { RegisterDraweeService } from 'src/app/services/register-drawee.service';

enum tabsEnum{
  company = "Empresa",
  contact = "Contato/Endereço",
  partner = "Sócios",
  documents = "Documentos"
}

@Component({
  selector: 'app-register-drawee-modal',
  templateUrl: './register-drawee-modal.component.html',
  styleUrls: ['./register-drawee-modal.component.scss']
})
export class RegisterDraweeModalComponent implements OnInit{
  tabs!: {title: string, isValid: boolean}[];
  tabsEnum = tabsEnum;
  isSavingData: any;

  constructor(
    public dialogRef: MatDialogRef<RegisterDraweeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private draweeService: RegisterDraweeService
  ){
    this.draweeService.setId(data.sacadoId);
  }

  ngOnInit(){
    this.setTabs();
    this.validationContact();
    this.validationPartner();
    this.validationDocuments();
  }

  setTabs(){
    this.tabs = [
      {title: tabsEnum.company, isValid: false},
      {title: tabsEnum.contact, isValid: false},
      {title: tabsEnum.partner, isValid: false},
      {title: tabsEnum.documents, isValid: false},
    ]
  }

  validationContact(){
    const addressObservable:Observable<Address> = this.draweeService.getCompany('address');
    const contactObservable:Observable<Contact[]> = this.draweeService.getCompany('contact');

    forkJoin([addressObservable, contactObservable]).subscribe({
      next: ([address, contact]) => {
        if(
          address.postalCode &&
          address.street &&
          address.number &&
          address.additionalInformation &&
          address.district &&
          address.city &&
          address.state &&
          contact.length
        ){
          this.tabs[1].isValid = true;
        }
      },
    })
  }

  validationPartner(){
    this.draweeService.getCompany('partners').subscribe({
      next: (partnerList: PartnerInterface[]) => {

        if(!partnerList.find(partner => partner.subscriber)) return;
        if(!partnerList.find(partner => partner.solidaryPartner)) return;

        for (let index = 0; index < partnerList.length; index++) {
          const partner = partnerList[index];

          if(
            !partner.cpfCnpj ||
            !partner.name ||
            !partner.phone ||
            !partner.qualificationCode ||
            !partner.mail
          )return;

          if(!partner.documents.find(doc => doc.type == 'IDENTIFICACAO')) return;
          if(!partner.documents.find(doc => doc.type == 'ENDERECO')) return;
        }

        this.tabs[2].isValid = true;
      }
    })
  }

  validationDocuments(){
    this.draweeService.getCompany('document').subscribe({
      next: (docType: any) => {
        if(
          docType.CONTRATO_SOCIAL && docType.CONTRATO_SOCIAL.length  &&
          docType.FACHADA_EMPRESA && docType.FACHADA_EMPRESA.length &&
          docType.INTERIOR_EMPRESA && docType.INTERIOR_EMPRESA.length &&
          docType.FATURAMENTO && docType.FATURAMENTO.length
        ) this.tabs[3].isValid = true;
      }
    })
  }
}

