import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthInterface } from '../interfaces/auth.operations.interface';
import { CreditInfoInterface } from '../interfaces/creditInfo.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated = !!sessionStorage.getItem('auth');
  creditInfo!: CreditInfoInterface;

  constructor(private http: HttpClient, public router: Router) {
    this.getCreditInfo();
  }

  getCreditInfo() {
    const auth = this.getAuth()?.auth;
    if (auth) {
      const url =
        environment.register_api +
        `secure-api/company/${auth}/config/monthlyPlan`;
      let headers = new HttpHeaders({
        skip: 'true',
      });

      this.http
        .get<CreditInfoInterface>(url, { headers })
        .subscribe((creditInfo) => {
          const remainingCredit =
            +creditInfo.creditLimit - +creditInfo.usedCreditLimit;

          this.creditInfo = {
            ...creditInfo,
            remainingCredit: remainingCredit.toString(),
          };
        });
    }
  }

  login(accessKey: string): any | Observable<any> {
    let headers = new HttpHeaders({
      skip: 'true',
    });

    return this.http
      .post<string>(
        environment.register_api + 'open/register/auth',
        accessKey,
        { headers, responseType: 'text' as 'json' }
      )
      .subscribe({
        next: (resData) => this.handleAuthentication(resData),
        error: (err) => this.handleAuthenticationError(err),
      });
  }

  private handleAuthenticationError(err: any) {
    this.router.navigate(['/error/not-authorized']);
    return throwError(err);
  }

  private handleAuthentication(accessToken: string) {
    const auth = atob(accessToken);

    sessionStorage.setItem('auth', auth);
    this.isAuthenticated = true;
    localStorage.clear();
    this.getCreditInfo();
    this.router.navigate(['/']);
  }

  logout() {
    sessionStorage.removeItem('auth');
    this.isAuthenticated = false;
  }

  getAuth(): AuthInterface {
    const accessToken = JSON.parse(sessionStorage.getItem('auth')!);
    return accessToken;
  }
}
