import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { SimulateReceivablesModalComponent } from '../simulate-receivables-modal/simulate-receivables-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { take, map, finalize } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { AditivoModalComponent } from '../aditivo-modal/aditivo-modal.component';
import { CurrencyPipe } from '@angular/common';
import { CreateBlob } from 'src/app/utils/create-blob';

import { NotifierService } from 'src/app/services/notifier.service';
import { OperationTypeList } from 'src/app/utils/operation-type-list';
import { RegisterDraweeModalComponent } from '../register-drawee-modal/register-drawee-modal.component';

@Component({
  selector: 'app-status-view',
  templateUrl: './status-view.component.html',
  styleUrls: ['./status-view.component.scss'],
})
export class StatusViewComponent implements AfterViewInit, OnInit {
  loading!: boolean;
  pageIndex: number = 0;
  pageSize: number = 20;
  currentExpandedItem?: OperationLead;
  isLoading = false;
  totalData!: number;
  totalFaceValue: number = 0;

  totalPayment!: number;
  leads: OperationLead[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() customerStatus!: string;

  displayedColumns: string[] = [
    'download',
    'registerDrawee',
    'sacado',
    'broker',
    'OperationType',
    'Parcela',
    'dueDateEnd',
    'type',
    'numberInvoice',
    'totalAmount',
    'valueLiquid',
    'Aditivo',
  ];
  filters?: FiltersInterface = { status: this.customerStatus };

  data!: OperationLead[];
  row!: OperationLead;
  selection = new SelectionModel<OperationLead>(true, []);

  openDialog() {
    const currentOperations = this.selection.selected;
    const dialogRef = this.dialog.open(SimulateReceivablesModalComponent, {
      data: { currentOperations },
    });
  }

  constructor(
    private customerDashboardService: customerDashboardService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private currencyPipe: CurrencyPipe,
    private notifierService: NotifierService
  ) {}

  ngOnInit(): void {
    this.getCustomers();
  }
  getTotalFaceValue() {
    this.customerDashboardService.getTotalFaceValue(this.filters).subscribe({
      next: (totalFaceValue) => {
        this.totalFaceValue = totalFaceValue;
      },
    });
  }
  ngAfterViewInit() {}

  lastPage = false;

  getCustomers(data?: FiltersInterface) {
    this.filters = { status: this.customerStatus };

    if (data) this.filters = { ...data, status: this.customerStatus };

    if (!this.lastPage) {
      this.isLoading = true;

      this.customerDashboardService
        .getAll(this.pageIndex, this.pageSize, this.filters)
        .pipe(
          map(({ content, ...rest }) => {
            const invoicesWithWrongInstallments =
              this.checkInvoicesWithWrongInstallments(content);

            if (invoicesWithWrongInstallments?.length) {
              invoicesWithWrongInstallments.forEach((invoiceNumber) => {
                content = content.map((invoice) => {
                  return this.correctInvoiceInstallments(
                    invoice,
                    invoiceNumber
                  );
                });
              });
            }
            return { content, ...rest };
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (data) => {
            const newData: OperationLead[] = data.content.map(
              (installment) => ({
                ...installment,
                operationType:
                  this.getNameTypeOperation(installment.operationType) ??
                  installment.operationType ??
                  '-',
                remainingValue:
                  installment.totalAmount - installment.amountPaid < 0
                    ? 0
                    : installment.totalAmount - installment.amountPaid,
              })
            );

            if (this.pageIndex === 0) this.leads = newData;
            else this.leads = this.leads.concat(newData);
            if (!data.last) this.pageIndex = this.pageIndex + 1;
            this.lastPage = data.last;
            this.getTotalFaceValue();
          },
        });
    }
  }

  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.customerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      });
  }

  openDialogAditive(additiveNumber: string) {
    const dialogRef = this.dialog.open(AditivoModalComponent, {
      data: { additiveNumber },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  handlePressRow(row: OperationLead) {
    if (!row) return;
    this.customerDashboardService
      .changeHistory(row.id, 'INSTALLMENT')
      .subscribe({
        next: (changeHistory) => {
          this.currentExpandedItem = row;

          if (this.currentExpandedItem?.paymentsHistory) {
            this.totalPayment = this.currentExpandedItem.paymentsHistory
              ?.map((p) => p.value)
              .reduce((total, p) => total + p, 0);
          }
          this.currentExpandedItem.changeHistory = changeHistory;
        },

        error: () => {
          this.notifierService.showNotification({
            message: 'Erro ao alterar status',
            alternative: 'Tente novamente',
            messageType: 'errorNotifier',
          });
        },
      });
  }
  handleSubmit(data?: FiltersInterface) {
    this.pageIndex = 0;
    this.lastPage = false;
    this.getCustomers(data);
  }
  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }
  handleCloseSidenav() {
    this.currentExpandedItem = undefined;
  }
  openDialogRegisterDrawee(row: any) {
    this.dialog.open(RegisterDraweeModalComponent, {
      data: { sacadoId: row.sacadoId },
    });
  }
  checkInvoicesWithWrongInstallments(
    invoice: OperationLead[]
  ): OperationLead['numberInvoice'][] {
    return invoice
      .filter((invoice) => {
        const installmentAsString = String(invoice.numberInstallment);
        const hasWrongInstallment =
          installmentAsString.startsWith('0') &&
          installmentAsString.charAt(1) === '/';
        return hasWrongInstallment;
      })
      .map((invoice) => invoice.numberInvoice);
  }

  correctInvoiceInstallments(
    invoice: OperationLead,
    invoiceNumber: OperationLead['numberInvoice']
  ) {
    if (invoice.numberInvoice === invoiceNumber) {
      const numberInstallmentAsString = String(invoice.numberInstallment);
      const [firstHalf, secondHalf] = numberInstallmentAsString.split('/');
      const correctNumber = +firstHalf + 1;
      const correctInstallmentNumber = String(correctNumber).concat(
        '/',
        secondHalf
      );
      return { ...invoice, numberInstallment: correctInstallmentNumber };
    }
    return { ...invoice };
  }
}
