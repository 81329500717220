<div class="main-container">
  <div class="banner-container">
    <div class="banner-container-wrapper">
      <div class="title-banner" *ngIf="draftTitles">
        <p class="warning">
          ATENÇÃO! Você possui títulos cadastrados incompletos. Faça a edição e
          finalize o cadastro.
        </p>
      </div>
    </div>
    <div class="add-invoice">
      <button
        mat-flat-button
        (click)="openDialog()"
        class="invoice-button"
        [ngStyle]="{
          'background-color': '#CC3179'
        }"
      >
        <mat-icon>add</mat-icon>Adicionar nota fiscal
      </button>
    </div>
  </div>
  <app-filters
    [tabStatus]="null"
    (filterEvent)="handleSubmit($event)"
  ></app-filters>

  <span class="draft-title" (click)="changeCustomersStatus()">{{
    draftTitleText
  }}</span>

  <section
    class="table-container mat-elevation-z8"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="getCustomers()"
    [scrollWindow]="false"
  >
    <table mat-table [dataSource]="leads">
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>
          <button class="table-button" mat-icon-button>
            <mat-icon>download</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            class="table-button"
            mat-icon-button
            (click)="getDownloadFilesOperation(row.id, row.numberInvoice!)"
          >
            <mat-icon>download</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="edit" *ngIf="customerStatus === 'SKETCH'">
        <th mat-header-cell *matHeaderCellDef>
          <button class="table-button" mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            class="table-button"
            mat-icon-button
            (click)="editInvoice(row.invoiceId)"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="sacado">
        <th mat-header-cell *matHeaderCellDef>Sacado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameSacado ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="broker">
        <th mat-header-cell *matHeaderCellDef>Broker</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameBroker ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Parcela">
        <th mat-header-cell *matHeaderCellDef>Parcela</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInstallment ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dueDateEnd">
        <th mat-header-cell *matHeaderCellDef>Vencimento</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dueDate ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.type ?? "N/A" | titleType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="numberInvoice">
        <th mat-header-cell *matHeaderCellDef>Número</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInvoice ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef>Valor de face</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.totalAmount | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="valueLiquid">
        <th mat-header-cell *matHeaderCellDef>
          Valor líquido
          <mat-icon
            matTooltip="Valor líquido considerando apenas o deságio. O valor pode mudar devido ao prazo de vencimento"
            >help</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element.amountToPay | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [matTooltipPosition]="'left'"
        matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
        [matTooltipDisabled]="!row.hasPending"
        [ngClass]="{ 'table-text-warning': row.hasPending }"
      ></tr>
    </table>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner color="accent"></mat-spinner>
    </div>
    <div class="container-blankslate" *ngIf="!isLoading && leads.length == 0">
      <p>Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
  </section>
  <div class="total-face-value">
    <span>Valor total de face:</span>

    <div>
      <span *ngIf="!isLoading">{{
        totalFaceValue ?? 0 | currency : "BRL"
      }}</span>
      <ngx-skeleton-loader
        *ngIf="isLoading"
        count="1"
        appearance="line"
        animation="progress"
        [theme]="{
          'background-color': '#595958',
          width: '90px',
          height: '20px',
          margin: '0'
        }"
      >
      </ngx-skeleton-loader>
    </div>
  </div>
</div>
