import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NoteRegistrationCompletedModalComponent } from '../../../../components/note-registration-completed-modal/note-registration-completed-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BankList } from 'src/app/utils/bank-list';
import { AuthService } from 'src/app/services/auth.service';
import { Bank } from 'src/app/interfaces/register-drawee.interface';
import { Router } from '@angular/router';
import { BankInterface } from '../../interfaces/bank.interface';
import { DraweeService } from '../../services/drawee.service';
import { InvoiceInstallmentsBankInterface } from '../../interfaces/invoiceInstallmentsBank.interface';

@Component({
  selector: 'app-receipt-data',
  templateUrl: './receipt-data.component.html',
  styleUrls: ['./receipt-data.component.scss'],
})
export class ReceiptDataComponent implements OnInit {
  form = new FormGroup({
    agency: new FormControl(),
    bank: new FormControl<BankInterface | null>(null),
    account: new FormControl(),
    accountDigit: new FormControl(),
    pix: new FormControl(),
  });
  banks: Bank[] = [];
  bankOptions = BankList.list();
  selectedBankId?: string;
  registeredInvoices: string[] = [];
  title?: string;
  isLoading = false;

  @Output() back = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private draweeService: DraweeService,
    private authService: AuthService,
    private router: Router
  ) {
    this.draweeService.registeredInvoices.subscribe({
      next: (value) => (this.registeredInvoices = value),
    });
    this.getBanks();
  }

  ngOnInit() {
    this.draweeService.registeredInvoices.subscribe({
      next: (invoices) => {
        this.title = `Você adicionou ${invoices.length} ${
          invoices.length > 1 ? 'notas fiscais' : 'nota fiscal'
        }. Onde deseja receber a antecipação? `;
      },
    });
  }

  getBanks() {
    const { auth } = this.authService.getAuth();
    this.draweeService.getBanks(auth).subscribe({
      next: (banks) => {
        this.banks = banks;
      },
    });
  }

  saveForm() {
    if (this.selectedBankId) {
      this.isLoading = true;
      const data: InvoiceInstallmentsBankInterface = {
        bankId: this.selectedBankId,
        invoiceIdList: this.registeredInvoices,
      };
      this.draweeService.addInvoiceInstallmentsBank(data).subscribe({
        next: () => {
          const dialogRef = this.dialog.open(
            NoteRegistrationCompletedModalComponent
          );
          this.isLoading = false;
          dialogRef
            .afterClosed()
            .subscribe({ next: () => this.router.navigate(['/']) });
        },
        error: () => {
          this.isLoading = false;
        },
      });
    }
  }

  addAccount() {
    const { account, accountDigit, agency, bank, pix } = this.form.value;
    const newBank: Bank = {
      account: account,
      accountDigit: accountDigit,
      agency: agency,
      name: bank?.ShortName,
      pix: pix,
      code: bank?.COMPE,
      purpose: 'REFERENCE',
      type: 'CORRENTE',
    };
    const { auth } = this.authService.getAuth();
    this.draweeService.addBank(auth, newBank).subscribe({
      next: (res) => {
        this.banks.push(res);
        this.form.reset();
      },
    });
  }

  selectBank(bankId: string) {
    this.selectedBankId = bankId;
  }
}
