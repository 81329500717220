import { Component } from '@angular/core';

@Component({
  selector: 'app-process-steps-modal',
  templateUrl: './process-steps-modal.component.html',
  styleUrls: ['./process-steps-modal.component.scss']
})
export class ProcessStepsModalComponent {

}
