<div mat-dialog-content>
  <div class="current-operations-container">
    <div class="operation-image-container">
      <img src="./../../../assets/png/trofeu 1.png" alt="" />
    </div>

    <div class="operation-title-container">
      <p class="bold-text">Antecipação solicitada!</p>
    </div>

    <div class="operation-description">
      <p class="description-text">
        Antecipação disponibilizada à venda! As suas notas serão
        disponibilizadas para fomentos parceiras! Após a análise, você receberá
        o e-mail para a assinatura dos documentos e o valor da antecipação na
        sua conta.
      </p>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button
      mat-flat-button
      mat-dialog-close
      color="accent"
      (click)="openDialogDocuments()"
    >
      Ok
    </button>
  </mat-dialog-actions>
</div>
