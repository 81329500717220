import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierComponent } from '../components/notifier/notifier.component';
import { NotifierInterface } from '../interfaces/notifier.interface';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  constructor(private snackBar: MatSnackBar) {}

  showNotification({ messageType, ...data }: NotifierInterface) {
    let config: object = {
      data,
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    };
    if (messageType) config = { ...config, panelClass: [messageType] };
    this.snackBar.openFromComponent(NotifierComponent, config);
  }


  getDataSuccess(){}

  getDataError(){
    this.showNotification({
      message: 'Ocorreu um erro ao exibir os dados.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }

  saveError(){
    this.showNotification({
      message: 'Ocorreu um erro ao salvar os dados.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }

  addBankSuccess(){
    this.showNotification({
      message: 'Dados bancários adicionados.',
      messageType: 'successNotifier',
      alternative: 'Item adicionado com sucesso!',
    })
  }

  addBankError(){
    this.showNotification({
      message: 'Erro ao adicionar dados de contato.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }

  dellBankSuccess(){
    this.showNotification({
      message: 'Dados bancários removidos.',
      messageType: 'successNotifier',
      alternative: 'Item deletado com sucesso!',
    })
  }
  dellBankError(){
    this.showNotification({
      message: 'Erro ao remover dados bancários.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }
    addContactSuccess(){
    this.showNotification({
      message: 'Dados de contato adicionados.',
      messageType: 'successNotifier',
      alternative: 'Item adicionado com sucesso!',
    })
  }

  addContactError(){
    this.showNotification({
      message: 'Erro ao adicionar dados de contato.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }

  dellContactSuccess(){
    this.showNotification({
      message: 'Dados de contato removidos.',
      messageType: 'successNotifier',
      alternative: 'Item deletado com sucesso!',
    })
  }

  dellContactError(){
    this.showNotification({
      message: 'Erro ao remover dados de contato.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }

  getContactError(){
    this.showNotification({
      message: 'Ocorreu erro ao exibir dados de contato.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }

  addDocSuccess(){
    this.showNotification({
      message: 'Documento(s) adicionado(s).',
      messageType: 'successNotifier',
      alternative: 'Item adicionado com sucesso!',
    })
  }

  addDocError(){
    this.showNotification({
      message: 'Erro ao adicionar documento(s).',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }

  dellDocSuccess(){
    this.showNotification({
      message: 'Documento(s) removido(s).',
      messageType: 'successNotifier',
      alternative: 'Item removido com sucesso!',
    })
  }

  dellDocError(){
    this.showNotification({
      message: 'Erro ao remover documento(s).',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }


  addPartnerSuccess(){
    this.showNotification({
      message: 'Sócio adicionado.',
      messageType: 'successNotifier',
      alternative: 'Item adicionado com sucesso!',
    })
  }

  addPartnerError(){
    this.showNotification({
      message: 'Erro ao adicionar sócio.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }

  dellPartnerSuccess(){
    this.showNotification({
      message: 'Sócio deletado.',
      messageType: 'successNotifier',
      alternative: 'Item removido com sucesso!',
    })
  }

  dellPartnerError(){
    this.showNotification({
      message: 'Erro ao remover sócio.',
      messageType: 'errorNotifier',
      alternative: 'Contate o time de operações!',
    })
  }
}
