<form [formGroup]="formFilters" class="filters-container">
  <mat-form-field appearance="outline" class="filter-cnpj-social">
    <mat-label>
      <mat-icon>search</mat-icon>
      Pesquisar por CNPJ/Razão Social
    </mat-label>
    <input
      class="input-autocomplete"
      placeholder="Digite o CNPJ ou a Razão Social"
      formControlName="nameActorOne"
      matInput
      type="text"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      [displayWith]="displayActorOne.bind(this)"
      #auto="matAutocomplete"
    >
      <mat-option
        *ngFor="let option of actorClientOptions['actorOne']"
        [value]="option.cnpj"
        >{{ option.name }} - {{ formatedCnpjLabel(option.cnpj) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="outline" class="filter-cnpj-social">
    <mat-label>
      <mat-icon>search</mat-icon>
      Número do Título
    </mat-label>
    <input
      class="input-autocomplete"
      placeholder="Número do titulo"
      formControlName="numberInvoice"
      matInput
      type="text"
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Período de Vencimento</mat-label>
    <mat-date-range-input [formGroup]="formFilters" [rangePicker]="picker">
      <input
        matStartDate
        placeholder="Data inicial"
        formControlName="dueDateInit"
      />
      <input matEndDate placeholder="Data final" formControlName="dueDateEnd" />
    </mat-date-range-input>

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <button
    mat-flat-button
    color="primary"
    type="submit"
    class="btn-filter"
    [ngStyle]="{
      'background-color': '#000'
    }"
    (click)="onSubmit()"
    type="button"
  >
    <mat-icon class="mr-1">filter_alt</mat-icon>
    <span>Filtrar</span>
  </button>
  <button
    mat-stroked-button
    (click)="clearFilters()"
    type="button"
    class="btn-filter"
  >
    <mat-icon class="">filter_alt_off</mat-icon>
  </button>
  <div class="gap"></div>
</form>
