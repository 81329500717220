<div class="tab-spacer"></div>

<mat-tab-group #tabGroup color="accent">
  <mat-tab label="Cadastrado">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Títulos cadastrados aguardando conferência da operação"
        matTooltipClass="example-tooltip-red1"
        ><mat-icon>assignment</mat-icon> Cadastrado
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-registered-titles></app-registered-titles>
    </ng-template>
  </mat-tab>
  <mat-tab label="Disponível">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Todas as notas disponíveis para antecipação"
        matTooltipClass="example-tooltip-red1"
        ><mat-icon>receipt</mat-icon> Disponível
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-disponibles-titles></app-disponibles-titles>
    </ng-template>
  </mat-tab>

  <mat-tab label="À venda">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="NFs à venda para fomentos parceiras"
        matTooltipClass="example-tooltip-red1"
        ><mat-icon>shopping_cart</mat-icon> À venda
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-for-sale></app-for-sale>
    </ng-template>
  </mat-tab>
  <mat-tab label="Em análise">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Títulos selecionados pelas fomentos"
        matTooltipClass="example-tooltip-red1"
        ><mat-icon>search</mat-icon> Em análise
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-analysis-titles-view></app-analysis-titles-view>
    </ng-template>
  </mat-tab>
  <mat-tab label="Documentos">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Antecipações pendentes de assinatura e pagamento"
        matTooltipClass="example-tooltip-red1"
        ><mat-icon>border_color</mat-icon> Aguardando assinatura
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-documents-titles></app-documents-titles>
    </ng-template>
  </mat-tab>

  <mat-tab label="Pagos">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Títulos pagos pela fomento"
        matTooltipClass="example-tooltip-red1"
      >
        <mat-icon>verified</mat-icon> Pagos
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-status-view
        [customerStatus]="customerStatusOptions.paid"
      ></app-status-view>
    </ng-template>
  </mat-tab>

  <mat-tab label="Liquidado">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Parcelas pagas pelo seu cliente"
        matTooltipClass="example-tooltip-red1"
      >
        <mat-icon>description</mat-icon> Liquidado
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-status-view
        [customerStatus]="customerStatusOptions.settled"
      ></app-status-view>
    </ng-template>
  </mat-tab>

  <mat-tab label="Em cobrança">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Parcelas em atraso"
        matTooltipClass="example-tooltip-red1"
      >
        <mat-icon>attach_money</mat-icon> Em cobrança
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-status-view
        [customerStatus]="customerStatusOptions.in_collection"
      ></app-status-view>
    </ng-template>
  </mat-tab>
</mat-tab-group>
