export const maskCnpj = (cnpj: string) =>
  cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

export const cnpjToString = (cnpj?: string | null) => {
  cnpj = cnpj?.replaceAll('.', '');
  cnpj = cnpj?.replace('-', '');
  cnpj = cnpj?.replace('/', '');

  return cnpj ?? '';
};
